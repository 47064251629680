import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Stack, Button, Paper } from "@material-ui/core";
import { GridTextField } from "../../../components/index";
import { textFiled, headerTable, header_csv } from "../../../functions/Static";
import DetailTaps from "../DetailTaps/DetailTaps";
import GridTable from "./GridTable";
import GridTableWithdraw from "./GridTableWithdraw";
import DatePicker from "react-date-picker";
import GridTableMT4 from "./GridTableMT4";
import { CSVLink } from "react-csv";

const ListFinancial = (props) => {
     //  const classes = useStyles();
     const {
          data_deposit,
          data_withdraw,
          language,
          page,
          handleChangePage,
          handleChangePageWithdraw,
          handleChangePageMt4,
          handleSearch,
          state,
          submitSearch,
          approveRejectDeposit,
          setTap,
          tap_value,
          page_withdraw,
          page_mt4,
          handleDateStartChange,
          handleDateEndChange,
          getImageSlip,
          getImageBank,
          select_withdraw,
          payment_type,
          sum_deposit,
          sum_withdraw,
          data_deposit_csv,
          data_withdraw_csv,
          data_mt4_csv,
          role,
     } = props;
     const count_deposit = Math.ceil(data_deposit.length > 0 && data_deposit.length / 10);
     const count_withdraw = Math.ceil(data_withdraw.length > 0 && data_withdraw.length / 10);
     const count_mt4 = Math.ceil(state.data_mt4.length > 0 && state.data_mt4.length / 10);
     const setPage = page - 1;
     const setPageWithdraw = page_withdraw - 1;
     const setPageMT4 = page_mt4 - 1;
     const dataList_deposit = data_deposit.slice(setPage * 10, page * 10);
     const dataList_withdraw = data_withdraw.slice(setPageWithdraw * 10, page_withdraw * 10);
     const dataList_mt4 = state.data_mt4.slice(setPageMT4 * 10, page_mt4 * 10);
     const menuItem = [
          {
               value: "null",
               label: "Select Withdarwal",
               disabled: true,
          },
          {
               value: "all",
               label: "All",
          },
          {
               value: 1,
               label: "ผ่าน Gateway",
          },
          {
               value: 2,
               label: "โอนเอง",
          },
          {
               value: 3,
               // label: "Bitcoins",
               label: "USDT",
          },
     ];
     const menuItem_status = [
          {
               value: "null",
               label: "Select Status",
          },
          {
               value: "all",
               label: "All",
          },
          {
               value: 1,
               label: "Success",
          },
          {
               value: 2,
               label: "Pending",
          },
          {
               value: 3,
               label: "Reject",
          },
     ];
     const select_type_withdraw = [
          {
               xs: 12,
               classNameGrid: "typography-profile pt-0",
               classNameText: "textfield-profile mt-2",
               placeholder: "Select Account Type",
               placeholder_th: "เลือกประเภทแอคเคาท์",
               name: "withdraw_type_search",
               type: "text",
               select: true,
               selectname: "menuItem",
          },
     ];
     const select_type_deposit = [
          {
               xs: 12,
               classNameGrid: "typography-profile pt-0",
               classNameText: "textfield-profile mt-2",
               placeholder: "Select Account Type",
               placeholder_th: "เลือกประเภทแอคเคาท์",
               name: "deposit_type_search",
               type: "text",
               select: true,
               selectname: "menuItem",
          },
     ];
     const select_status = [
          {
               xs: 12,
               classNameGrid: "typography-profile pt-0",
               classNameText: "textfield-profile mt-2",
               placeholder: "Select Account Type",
               placeholder_th: "เลือกประเภทแอคเคาท์",
               name: "status_search",
               type: "text",
               select: true,
               selectname: "menuItem",
          },
     ];

     const summary_mt4 = [
          { label: "ยอดรวมฝาก MT4", sum: state.sum_mt4_deposit || "0.00" },
          { label: "ยอดรวมถอน MT4", sum: state.sum_mt4_withdraw || "0.00" },
          { label: "กำไร/ขาดทุนใน MT4", sum: state.sum_mt4_profits || "0.00" },
     ];

     const data = [
          { firstName: "Warren", lastName: "Morrow", email: "sokyt@mailinator.com", age: "36" },
          { firstName: "Gwendolyn", lastName: "Galloway", email: "weciz@mailinator.com", age: "76" },
          { firstName: "Astra", lastName: "Wyatt", email: "quvyn@mailinator.com", age: "57" },
          { firstName: "Jasmine", lastName: "Wong", email: "toxazoc@mailinator.com", age: "42" },
          { firstName: "Brooke", lastName: "Mcconnell", email: "vyry@mailinator.com", age: "56" },
          { firstName: "Christen", lastName: "Haney", email: "pagevolal@mailinator.com", age: "23" },
          { firstName: "Tate", lastName: "Vega", email: "dycubo@mailinator.com", age: "87" },
          { firstName: "Amber", lastName: "Brady", email: "vyconixy@mailinator.com", age: "78" },
          { firstName: "Philip", lastName: "Whitfield", email: "velyfi@mailinator.com", age: "22" },
          { firstName: "Kitra", lastName: "Hammond", email: "fiwiloqu@mailinator.com", age: "35" },
          { firstName: "Charity", lastName: "Mathews", email: "fubigonero@mailinator.com", age: "63" },
     ];

     const csvReport = {
          data: data_deposit_csv,
          headers: header_csv.headers_deposit,
          filename: "deposit_report.csv",
     };
     const csvReportWithdraw = {
          data: data_withdraw_csv,
          headers: header_csv.headers_withdraw,
          filename: "withdraw_report.csv",
     };
     const csvReportMT4 = {
          data: data_mt4_csv,
          headers: header_csv.headers_mt4,
          filename: "mt4_report.csv",
     };

     return (
          <Grid container spacing={5} justifyContent="center" alignItems="center">
               <Grid item xs={12}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>Financial</Typography>
               </Grid>
               <Grid container item rowSpacing={2}>
                    <Grid item xs={12} sm={3} mr={1}>
                         <DatePicker className={"date-picker"} onChange={handleDateStartChange} value={state.date_start} format="dd/MM/yyyy" />
                    </Grid>
                    <Grid item xs={12} sm={3} mr={1}>
                         <DatePicker className={"date-picker"} onChange={handleDateEndChange} value={state.date_end} format="dd/MM/yyyy" />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                         <Button
                              variant="contained"
                              style={{ backgroundColor: "#4589C6" }}
                              //  className="btn-style"
                              sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                              onClick={submitSearch}
                         >
                              {language === "th" ? "ค้นหา" : "Search"}
                         </Button>
                    </Grid>
               </Grid>
               <Grid item xs={12}>
                    <DetailTaps role={role} setTap={setTap} />
               </Grid>
               <Grid container item spacing={2}>
                    {tap_value !== 3 && (
                         <Grid item xs={12} sm={3} mr={1}>
                              <GridTextField
                                   data={textFiled.search_fin}
                                   state={state}
                                   handleChange={handleSearch}
                                   onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                             submitSearch();
                                        }
                                   }}
                              />
                         </Grid>
                    )}
                    {tap_value === 1 && (
                         <Grid item xs={12} sm={3} mr={1}>
                              <GridTextField data={select_type_deposit} state={state} language={language} handleChange={handleSearch} menuItem={payment_type} />
                         </Grid>
                    )}
                    {tap_value === 2 && (
                         <Grid item xs={12} sm={3} mr={1}>
                              <GridTextField data={select_type_withdraw} state={state} language={language} handleChange={handleSearch} menuItem={menuItem} />
                         </Grid>
                    )}
                    {tap_value === 3 && null}
                    {tap_value !== 3 && (
                         <>
                              {/* {role !== "normal" && ( */}
                              {(role !== "normal" && role !== "customer_officer") && (
                                   <Grid item xs={12} sm={3} mr={1}>
                                        <GridTextField data={select_status} state={state} language={language} handleChange={handleSearch} menuItem={menuItem_status} />
                                   </Grid>
                              )}
                              <Grid item xs={12} sm={2}>
                                   <Button
                                        variant="contained"
                                        style={{ backgroundColor: "#4589C6" }}
                                        //  className="btn-style"
                                        sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                                        onClick={submitSearch}
                                   >
                                        {language === "th" ? "ค้นหา" : "Search"}
                                   </Button>
                              </Grid>
                         </>
                    )}
               </Grid>
               <Grid item xs={12}>
                    {tap_value === 1 ? (
                         <>
                              <Grid item container mb={2} justifyContent="space-between" spacing={2}>
                                   <Grid item xs={12} sm={5} md={3}>
                                        <Paper elevation={3} sx={{ width: "100%" }}>
                                             <Grid container p={2} spacing={2}>
                                                  <Grid item xs={12}>
                                                       <Typography variant="h5" fontWeight={600}>
                                                            ยอดฝากรวม
                                                       </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} display="flex" justifyContent="center">
                                                       <Typography variant="h5">{Number(sum_deposit).toFixed(4) || "0.00"} $</Typography>
                                                  </Grid>
                                             </Grid>
                                        </Paper>
                                   </Grid>
                                   <Grid item xs={12} sm={3} display="flex" justifyContent="center" alignItems="center">
                                        <CSVLink {...csvReport} style={{ color: "#fff", textDecoration: "none" }}>
                                             <Button
                                                  variant="contained"
                                                  style={{ backgroundColor: "#4589C6" }}
                                                  sx={{ maxWidth: 150, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                                             >
                                                  export data
                                             </Button>
                                        </CSVLink>
                                   </Grid>
                              </Grid>
                              <GridTable
                                   // head={headerTable.fin}
                                   head={role === "admin" ? headerTable.fin : (role === "normal" || role === "customer_officer") ? headerTable.fin_normal : headerTable.fin_sale}
                                   body={dataList_deposit}
                                   count={count_deposit}
                                   stateFinance={state}
                                   page={page}
                                   handleChangePage={handleChangePage}
                                   language={language}
                                   approveRejectDeposit={approveRejectDeposit}
                                   onChange={handleSearch}
                                   getImageSlip={getImageSlip}
                              />
                         </>
                    ) : tap_value === 2 ? (
                         <>
                              <Grid item container mb={2} justifyContent={"space-between"} spacing={2}>
                                   <Grid item xs={12} sm={5} md={3}>
                                        <Paper elevation={3} sx={{ width: "100%" }}>
                                             <Grid container p={2} spacing={2}>
                                                  <Grid item xs={12}>
                                                       <Typography variant="h5" fontWeight={600}>
                                                            ยอดถอนรวม
                                                       </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} display="flex" justifyContent="center">
                                                       <Typography variant="h5">{Math.round(Number(sum_withdraw) * 100) / 100 || "0.00"} $</Typography>
                                                  </Grid>
                                             </Grid>
                                        </Paper>
                                   </Grid>
                                   <Grid item xs={12} sm={3} display="flex" justifyContent="center" alignItems="center">
                                        <CSVLink {...csvReportWithdraw} style={{ color: "#fff", textDecoration: "none" }}>
                                             <Button
                                                  variant="contained"
                                                  style={{ backgroundColor: "#4589C6" }}
                                                  sx={{ maxWidth: 150, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                                             >
                                                  export data
                                             </Button>
                                        </CSVLink>
                                   </Grid>
                              </Grid>
                              <GridTableWithdraw
                                   head={role === "admin" ? headerTable.fin_withdraw : (role === "normal" || role === "customer_officer") ? headerTable.fin_withdraw_normal : headerTable.fin_withdraw_sale}
                                   body={dataList_withdraw}
                                   count={count_withdraw}
                                   stateFinance={state}
                                   page={page_withdraw}
                                   handleChangePage={handleChangePageWithdraw}
                                   language={language}
                                   approveRejectWithdraw={props.approveRejectWithdraw}
                                   onChange={handleSearch}
                                   getImageBank={getImageBank}
                                   select_withdraw={select_withdraw}
                                   setValue={props.setValue}
                              />
                         </>
                    ) : tap_value === 3 ? (
                         <>
                              <Grid item container >
                                   <Grid item container spacing={2} xs={12} sm={10} md={10}>
                                        {summary_mt4.map((el, i) => (
                                             <Grid item xs={12} sm={4} md={3} container mb={2}>
                                                  <Paper elevation={3} sx={{ width: "100%" }}>
                                                       <Grid container p={2} spacing={2}>
                                                            <Grid item xs={12}>
                                                                 <Typography variant="h5" fontWeight={600}>
                                                                      {el.label}
                                                                 </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} display="flex" justifyContent="center">
                                                                 <Typography variant="h5">{Number(el.sum).toFixed(2)} $</Typography>
                                                            </Grid>
                                                       </Grid>
                                                  </Paper>
                                             </Grid>
                                        ))}
                                   </Grid>
                                   <Grid item xs={12} sm={2} md={2} display="flex" justifyContent="center" alignItems="center">
                                        <CSVLink {...csvReportMT4} style={{ color: "#fff", textDecoration: "none" }}>
                                             <Button
                                                  variant="contained"
                                                  style={{ backgroundColor: "#4589C6" }}
                                                  sx={{ maxWidth: 150, fontSize: 16, width: "100%", borderRadius: 40, minHeight: 40, mb: 2, maxHeight: 60 }}
                                             >
                                                  export data
                                             </Button>
                                        </CSVLink>
                                   </Grid>
                              </Grid>
                              <GridTableMT4 head={headerTable.fin_mt4} body={dataList_mt4} page={page_mt4} count={count_mt4} handleChangePage={handleChangePageMt4} />
                         </>
                    ) : null}
               </Grid>
          </Grid>
     );
};

export default withRouter(ListFinancial);
