import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
const theme = createMuiTheme();

const GridTableLot = (props) => {
    const classes = useStyles();
    const sub_head = [{ label: "Forex" }, { label: "Gold" }, { label: "Crypto" }, { label: "Index" }, { label: "Oil" }];
    const sub_body = [{ label: "forex" }, { label: "gold" }, { label: "crypto" }, { label: "index" }, { label: "oil" }];

    return (
        <Grid className="table-btn" my={3}>
            < TableContainer >
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" rowSpan={2}>
                                <Typography noWrap>ประเภทบัญชี</Typography>
                            </TableCell>
                            <TableCell align="center" colSpan={5}>
                                <Typography noWrap>จำนวน Lot</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {sub_head.map((e, ind) => {
                                return (
                                    <TableCell key={ind} align="center" style={{ minWidth: 100 }}>
                                        <Typography noWrap>{e.label}</Typography>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.body.map((e, ind) => {
                            return (
                                <TableRow hover key={ind} className={ind % 2 !== 0 ? "bg-table" : ""}>
                                    <TableCell >
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }} >
                                            {e.account_type_name}
                                        </Typography>
                                    </TableCell>
                                    {sub_body.map((el, ind) =>
                                        <TableCell key={ind} align="right">
                                            <Typography noWrap sx={{ fontSize: 16, color: "#707070" }} >
                                                {Number(e[el.label]).toFixed(2)}
                                            </Typography>
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container justifyContent="center" mt={5}>
                {props.body.length === 0 && (
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                        {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                    </Typography>
                )}
                {/* {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />} */}
            </Grid>
        </Grid >
    );
};
const useStyles = makeStyles(() => ({
    table: {
        minWidth: 400,
    },
    fontStyleTitle: {
        fontSize: 18,
        fontWeight: 600,
        color: "#000",
    },
    fontStyleNav: {
        fontSize: 13,
        fontWeight: "bold",
        color: "#000",
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            fontSize: 18,
        },
    },
    fontStyleDetail: {
        fontSize: 13,
        color: "#000",
        // textAlign: "justify",
        [theme.breakpoints.up("md")]: {
            fontSize: 18,
        },
        marginTop: 10,
        marginBottom: 10,
    },
    fontStyle: {
        fontSize: 13,
        color: "#000",
        [theme.breakpoints.up("md")]: {
            fontSize: 18,
        },
        display: "flex",
        justifyContent: "center",
    },
}));
export default GridTableLot;
