import { Grid, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const GridTableMember = (props) => {
    const classes = useStyles();

    const count = Math.ceil(props.data.length > 0 && props.data.length / 10);
    const setPage = props.page - 1;
    const body = props.data.slice(setPage * 10, props.page * 10);
    //-------------------------------------------------//
    return (
        <Grid className="table-btn">
            <TableContainer>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {props.head.map((el, i) => {
                                return (
                                    <TableCell key={i} align={el.align || "left"}>
                                        <Typography noWrap>{el.label}</Typography>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {body.map((el, i) => {
                            return (
                                <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                    <TableCell >
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {el.name} {el.lastname}
                                        </Typography>
                                    </TableCell>
                                    {props.type !== "saleManagerSaleTeam" &&
                                        <TableCell >
                                            <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                {el.email}
                                            </Typography>
                                        </TableCell>
                                    }
                                    <TableCell align="center">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {el.phone}
                                        </Typography>
                                    </TableCell>
                                    {props.type === "saleManagerTeam" &&
                                        <TableCell>
                                            <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                {el.admin_name} {el.admin_lastname}
                                            </Typography>
                                        </TableCell>
                                    }
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container justifyContent="center" mt={5}>
                {body.length === 0 && (
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                        {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                    </Typography>
                )}
                {body.length > 0 && <Pagination count={count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles({
    table: {
        minWidth: 320,
    },
    fontActive: {
        fontSize: 15,
        color: "#fff",
        padding: 8,
        borderRadius: 10,
    },
});

export default GridTableMember;
