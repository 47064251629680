import { Autocomplete, Button, Grid, TextField, Typography } from "@material-ui/core";
import HeaderTypography from "components/HeaderTypography/HeaderTypography";
import Sidebar from "./Sidebar";

const SettingSaleTeamManage = (props) => {
    const {
        state,
        profile,
        language,
        onClickEdit,
        onChangeTeam,
    } = props;
    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <Sidebar title={"Add Sale Team"} pageName={"sale_manager"} />
            </Grid>
            <Grid item container spacing={2} direction="row" xs={9} justifyContent="center" alignContent={"flex-start"}>
                <Grid item xs={12}>
                    {/* <HeaderTypography title={language === "th" ? "แก้ไขทีม" : "Edit Sale Team"} /> */}
                    <HeaderTypography title={"Edit Sale Team"} />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                    <Grid Grid container item xs={12} spacing={2} marginBottom={2}>
                        <Grid item xs={12} md={4}>
                            <Typography fontSize={{ xs: 14, sm: 16, md: 16 }}>Username</Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography fontSize={{ xs: 14, sm: 16, md: 16 }} fontWeight="bold">{profile}</Typography>

                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography fontSize={{ xs: 14, sm: 16, md: 16 }}>Team</Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                getOptionLabel={(option) =>
                                    typeof option === 'string' ? option : `${option.name} ${option.lastname}`
                                }
                                options={state.data_team}
                                value={state.select_data_team}
                                freeSolo
                                onChange={(event, value) => onChangeTeam(value)}
                                renderInput={(params) => <TextField {...params} variant="standard" placeholder="Team Sale select" />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                    <Button
                        variant="contained"
                        style={{ backgroundColor: "#4589C6" }}
                        className="btn-style"
                        sx={{ maxWidth: 200, fontSize: 12, width: "100%", borderRadius: 40, height: 50, margin: 1 }}
                        onClick={onClickEdit}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SettingSaleTeamManage;
