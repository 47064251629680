import th from "../assets/logo/ThailandFlag.png";
import en from "../assets/logo/ENFlag.png";
import call from "../assets/logo/phone-call.png";
import mail from "../assets/logo/email.png";
import location from "../assets/logo/location.png";
import facebook from "../assets/logo/facebook.png";
import line from "../assets/logo/line.png";
import youtube from "../assets/logo/youtube.png";
import username from "../assets/logo/username.png";
import password from "../assets/logo/password.png";

export const sideBar = {
  // one: [
  //      {
  //           th: "นโยบาย",
  //           en: "Policy",
  //           link: "/policy",
  //      },
  //      {
  //           th: "คำถามที่พบบ่อย",
  //           en: "Question",
  //           link: "/question",
  //      },
  //      {
  //           th: "ติดต่อเรา",
  //           en: "Contact",
  //           link: "/contact",
  //      },
  // ],
  admin: [
    {
      th: "หน้าหลัก",
      en: "Dashboard",
      link: "/dashboard",
    },
    // {
    //      th: "สมาชิก",
    //      en: "Member List",
    //      link: "/members",
    // },
    {
      th: "รายการ IB",
      en: "IB List",
      link: "/iblist",
    },
    {
      th: "รายการ IB Rebate",
      en: "IB rebate List",
      link: "/ibrebatelist",
    },
    {
      th: "ย้ายสายงาน",
      en: "IB Transfer",
      link: "/ibTransfer",
    },
    // {
    //      th: "รายการ Sale",
    //      en: "Sale list",
    //      link: "/sale",
    // },
    {
      th: "การเงิน",
      en: "Financial",
      link: "/financial",
    },
    {
      th: "รายการ Cashback",
      en: "Cashback",
      link: "/cashback",
    },
    {
      th: "รายการฝาก-ถอน MT",
      en: "Transaction MT",
      link: "/transactionMT4",
    },
    {
      th: "รายการฝาก-ถอน IB",
      en: "IB Transaction",
      link: "/ibTransaction",
    },
    {
      th: "รายงาน MT5",
      en: "ReportMT5",
      link: "/reportMT5",
    },
    {
      th: "ลบ MT5",
      en: "Delete MT5",
      link: "/deleteMT",
    },
    {
      th: "อัตราแลกเปลี่ยน",
      en: "Exchange Rate",
      link: "/exchange",
    },
    {
      th: "สรุปรายการ Sale",
      en: "Manager",
      link: "/manager",
    },
    {
      th: "บัญชีซื้อขาย",
      en: "Trading Accounts",
      link: "/trading",
    },
    // {
    //      th: "อินบ๊อกซ์",
    //      en: "Inbox",
    //      link: "/inbox",
    // },
    // {
    //      th: "รายได้ Broker",
    //      en: "Broker Income",
    //      link: "/broker_income",
    // },
    {
      th: "ซัพพอร์ต",
      en: "Support",
      link: "/support",
    },
    {
      th: "รายการโปรโมชัน",
      en: "Promotion List",
      link: "/promotion_list",
    },
    {
      th: "ตั้งค่า",
      en: "Setting",
      link: "/setting_wallet_control",
    },
    {
      th: "ออกจากระบบ",
      en: "Logout",
      link: "/login",
    },
  ],
  sale: [
    {
      th: "หน้าหลัก",
      en: "Dashboard",
      link: "/dashboard",
    },
    {
      th: "รายการ IB",
      en: "IB List",
      link: "/iblist",
    },
    {
      th: "รายการ IB Rebate",
      en: "IB rebate List",
      link: "/ibrebatelist",
    },
    {
      th: "รายการ Sale",
      en: "Sale list",
      link: "/sale",
    },
    {
      th: "การเงิน",
      en: "Financial",
      link: "/financial",
    },
    {
      th: "บัญชีซื้อขาย",
      en: "Trading Accounts",
      link: "/trading",
    },
    {
      th: "ซัพพอร์ต",
      en: "Support",
      link: "/support",
    },
    {
      th: "ออกจากระบบ",
      en: "Logout",
      link: "/login",
    },
  ],
  sale_manager: [
    {
      th: "หน้าหลัก",
      en: "Dashboard",
      link: "/dashboard",
    },
    {
      th: "รายการ IB",
      en: "IB List",
      link: "/iblist",
    },
    {
      th: "รายการ IB Rebate",
      en: "IB rebate List",
      link: "/ibrebatelist",
    },
    {
      th: "รายการ Sale",
      en: "Sale list",
      link: "/sale",
    },
    {
      th: "รายการทีม Sale",
      en: "Team Sale List",
      link: "/accountmanager",
    },
    {
      th: "การเงิน",
      en: "Financial",
      link: "/financial",
    },
    {
      th: "บัญชีซื้อขาย",
      en: "Trading Accounts",
      link: "/trading",
    },
    {
      th: "ซัพพอร์ต",
      en: "Support",
      link: "/support",
    },
    {
      th: "ออกจากระบบ",
      en: "Logout",
      link: "/login",
    },
  ],
  normal: [
    {
      th: "หน้าหลัก",
      en: "Dashboard",
      link: "/dashboard",
    },
    {
      th: "รายการ IB",
      en: "IB List",
      link: "/iblist",
    },
    // {
    //   th: "IB Rebate List",
    //   en: "IB Rebate List",
    //   link: "/ibrebatelist",
    // },
    {
      th: "การเงิน",
      en: "Financial",
      link: "/financial",
    },
    {
      th: "รายการฝาก-ถอน MT",
      en: "Transaction MT",
      link: "/transactionMT4",
    },
    {
      th: "ลบ MT5",
      en: "Delete MT5",
      link: "/deleteMT",
    },
    {
      th: "รายการฝาก-ถอน IB",
      en: "IB Transaction",
      link: "/ibTransaction",
    },
    {
      th: "รายงาน MT5",
      en: "ReportMT5",
      link: "/reportMT5",
    },
    // {
    //   th: "สรุปรายการ Sale",
    //   en: "Manager",
    //   link: "/manager",
    // },
    {
      th: "ซัพพอร์ต",
      en: "Support",
      link: "/support",
    },
    {
      th: "ออกจากระบบ",
      en: "Logout",
      link: "/login",
    },
  ],
  customer_officer: [
    {
      th: "หน้าหลัก",
      en: "Dashboard",
      link: "/dashboard",
    },
    {
      th: "รายการ IB",
      en: "IB List",
      link: "/iblist",
    },
    {
      th: "การเงิน",
      en: "Financial",
      link: "/financial",
    },
    {
      th: "รายการฝาก-ถอน MT",
      en: "Transaction MT",
      link: "/transactionMT4",
    },
    {
      th: "ลบ MT5",
      en: "Delete MT5",
      link: "/deleteMT",
    },
    {
      th: "รายงาน MT5",
      en: "ReportMT5",
      link: "/reportMT5",
    },
    {
      th: "ซัพพอร์ต",
      en: "Support",
      link: "/support",
    },
    {
      th: "ออกจากระบบ",
      en: "Logout",
      link: "/login",
    },
  ],
  three: [
    {
      th: "หน้าหลัก",
      en: "Dashboard",
      link: "/dashboard",
    },
    {
      th: "สมาชิก",
      en: "Member List",
      link: "/profile_detail",
    },
    {
      th: "รายงานการเทรด",
      en: "Trading Report",
      link: "/report_trading",
    },
    {
      th: "จัดการลูกค้า",
      en: "Mange User",
      link: "/manage_user",
    },
    {
      th: "Network",
      en: "Network",
      link: "/network_user",
    },
    {
      th: "สมาชิก",
      en: "Member List",
      link: "/members",
    },
    {
      th: "IB List",
      en: "IB List",
      link: "/iblist",
    },
    {
      th: "IB Detail",
      en: "IB Detail",
      link: "/ibdetail",
    },
    {
      th: "IB Detail Nest",
      en: "IB Detail Nest",
      link: "/ibdetailnest",
    },
    // {
    //   th: "Edit Rebate",
    //   en: "Edit Rebate",
    //   link: "/editrebate",
    // },
    {
      th: "IB Rebate List",
      en: "IB Rebate List",
      link: "/ibrebatelist",
    },
    {
      th: "IB Rebate Detail",
      en: "IB Rebate Detail",
      link: "/ibrebatedetail",
    },
    {
      th: "ย้ายสายงาน",
      en: "IB Transfer",
      link: "/ibTransfer",
    },
    {
      th: "Edit Type Account Rebate",
      en: "Edit Type Account Rebate",
      link: "/edittypeaccountrebate",
    },
    {
      th: "IB Rebate Campaign",
      en: "IB Rebate Campaign",
      link: "/ibrebatecampaign",
    },
    {
      th: "การเงิน",
      en: "Financial",
      link: "/financial",
    },
    {
      th: "รายการ Cashback",
      en: "Cashback",
      link: "/cashback",
    },
    {
      th: "รายการฝาก-ถอน MT",
      en: "Transaction MT",
      link: "/transactionMT4",
    },
    {
      th: "รายการฝาก-ถอน IB",
      en: "IB Transaction",
      link: "/ibTransaction",
    },
    {
      th: "รายงาน MT5",
      en: "ReportMT5",
      link: "/reportMT5",
    },
    {
      th: "ลบ MT5",
      en: "Delete MT5",
      link: "/deleteMT",
    },
    {
      th: "Exchange Rate",
      en: "Exchange Rate",
      link: "/exchange",
    },
    {
      th: "รายได้ Broker",
      en: "Broker Income",
      link: "/broker_income",
    },
    {
      th: "Trading Accounts",
      en: "Trading Accounts",
      link: "/trading",
    },
    {
      th: "อินบ๊อกซ์",
      en: "Inbox",
      link: "/inbox",
    },
    {
      th: "รายละเอียดปัญหา",
      en: "Problem Detail",
      link: "/support/detail",
    },
    {
      th: "สรุปรายการ Sale",
      en: "Manager",
      link: "/manager",
    },
    {
      th: "สมาชิก",
      en: "Member Sale",
      link: "/memberdetail",
    },
    {
      th: "สมาชิก",
      en: "Member Sale",
      link: "/accountmanager/memberdetail",
    },
    {
      th: "รายการ Transaction",
      en: "Transaction List",
      link: "/transactiondetail",
    },
    {
      th: "รายการ Transaction",
      en: "Transaction List",
      link: "/accountmanager/transactiondetail",
    },
    {
      th: "รายการ Lot",
      en: "Lot List",
      link: "/lotdetail",
    },
    {
      th: "รายการ Lot",
      en: "Lot List",
      link: "/accountmanager/lotdetail",
    },
    {
      th: "ซัพพอร์ต",
      en: "Support",
      link: "/support",
    },
    {
      th: "รายการโปรโมชัน",
      en: "Promotion List",
      link: "/promotion_list",
    },
    {
      th: "เพิ่มโปรโมชัน",
      en: "Promotion Add",
      link: "/promotion_add",
    },
    {
      th: "แก้ไขโปรโมชัน",
      en: "Promotion Edit",
      link: "/promotion_edit",
    },
    // {
    //      th: "ตั้งค่า",
    //      en: "Setting",
    //      link: "/setting",
    // },
    {
      th: "Wallet Control",
      en: "Wallet Control",
      link: "wallet_control",
    },
    {
      th: "Setup Deposit",
      en: "Setup Deposit",
      link: "setup_deposit",
    },
    {
      th: "Setup Withdrawal",
      en: "Setup Withdrawal",
      link: "setup_withdrawal",
    },
    {
      th: "Setup System",
      en: "Setup System",
      link: "/not-found",
    },
    {
      th: "Setup Group Leverage",
      en: "Setup Group Leverage",
      link: "/not-found",
    },
    {
      th: "Service",
      en: "Service",
      link: "setting_service",
    },
    {
      th: "Policy",
      en: "Policy",
      link: "/setting_policy",
    },
    {
      th: "Add Policy",
      en: "Add Policy",
      link: "/setting_manage_policy/add",
    },
    {
      th: "Edit Policy",
      en: "Edit Policy",
      link: "/setting_manage_policy/edit",
    },
    // {
    //      th: "Question",
    //      en: "Question",
    //      link: "/setting_question",
    // },
    {
      th: "มาร์เก็ตติ้ง",
      en: "Marketing",
      link: "setting_marketing",
    },

    {
      th: "ข่าว",
      en: "News",
      link: "setting_news",
    },
    {
      th: "โปรโมชั่น",
      en: "Promotion",
      link: "setting_promotion",
    },
    {
      th: "เพิ่มข่าว",
      en: "Add News",
      link: "setting_manage_news/add",
    },
    {
      th: "แก้ไขข่าว",
      en: "Edit News",
      link: "setting_manage_news/edit",
    },
    {
      th: "เพิ่มโปรโมชั่น",
      en: "Add Promotion",
      link: "manage_promotion/add",
    },
    {
      th: "แก้ไขโปรโมชั่น",
      en: "Edit Promotion",
      link: "manage_promotion/edit",
    },
    {
      th: "เพิ่มมาร์เก็ตติ้ง",
      en: "Add Marketing",
      link: "manage_market/add",
    },
    {
      th: "แก้ไขมาร์เก็ตติ้ง",
      en: "Edit Marketing",
      link: "manage_market/edit",
    },
    {
      th: "ประเภทบัญชี",
      en: "Account Type",
      link: "setting_account_type",
    },
    {
      th: "แก้ไขประเภทแอคเคาท์",
      en: "Edit Account Type",
      link: "setting_manage_account_type/edit",
    },
    {
      th: "เพิ่มประเภทแอคเคาท์",
      en: "Add Account Type",
      link: "setting_manage_account_type/add",
    },
    {
      th: "เพิ่มเซอร์วิซ",
      en: "Add Service",
      link: "/setting_manage_service/add",
    },
    {
      th: "แก้ไขเซอร์วิซ",
      en: "Edit Service",
      link: "/setting_manage_service",
    },
    {
      th: "แบนเนอร์",
      en: "Banner",
      link: "/setting_banner",
    },
    {
      th: "FAQ",
      en: "FAQ",
      link: "/setting_question",
    },
    {
      th: "เพิ่ม FAQ",
      en: "Add FAQ",
      link: "/setting_manage_question/add",
    },
    {
      th: "แก้ไข FAQ",
      en: "Edit FAQ",
      link: "/setting_manage_question/edit",
    },
    {
      th: "เพิ่มผู้ดูแล",
      en: "Add Admin",
      link: "/admin/add",
    },
    {
      th: "ผู้ดูแล",
      en: "Admin",
      link: "/admin",
    },
    {
      th: "เปลี่ยนรหัสผ่าน",
      en: "Change Password",
      link: "/change_password",
    },
    {
      th: "เปลี่ยนรหัสผ่าน",
      en: "Reset Password",
      link: "/reset_password",
    },
    {
      th: "รายการ Sale",
      en: "Sale list",
      link: "/sale",
    },
    {
      th: "รายการทีม Sale",
      en: "Team Sale List",
      link: "/accountmanager",
    },
    {
      th: "Rebate",
      en: "Rebate",
      link: "/rebate",
    },
    {
      th: "ถอน",
      en: "Withdraw",
      link: "/setting_withdraw",
    },
    {
      th: "ตั้งค่าการถอนอัตโนมัติ",
      en: "Setup Auto Withdraw",
      link: "/setup_auto_withdraw",
    },
    {
      th: "ตั้งค่า E-mail",
      en: "Setting E-mail",
      link: "/setting_email",
    },
    {
      th: "แก้ไขตั้งค่า E-mail",
      en: "Edit Setting E-mail",
      link: "setting_email/edit",
    },
    {
      th: "ตั้งค่าโบนัส",
      en: "Bonus Setting",
      link: "/bonus_setting",
    },
    {
      th: "เพิ่มทีม Sale",
      en: "Add Sale Team",
      link: "/setting_sale_manager/edit",
    },
    {
      th: "Sale Team",
      en: "Sale Team",
      link: "/setting_sale_manager",
    },
  ],
  setting: [
    {
      th: "ควบคุมกระเป๋า",
      en: "Wallet Control",
      link: "/setting_wallet_control",
    },
    {
      th: "ตั้งค่าการฝาก",
      en: "Setup Deposit",
      link: "/setting_setup_deposit",
    },
    {
      th: "ตั้งค่าการถอน",
      en: "Setup Withdrawal",
      link: "/setting_setup_withdrawal",
    },
    {
      th: "ตั้งค่าระบบ",
      en: "Setup System",
      link: "/not-found",
    },
    {
      th: "ตั้งค่ากลุ่มเลเวอเรจ",
      en: "Setup Group Leverage",
      link: "/not-found",
    },
    {
      th: "บริการ",
      en: "Service",
      link: "/setting_service",
    },
    {
      th: "ประเภทบัญชี",
      en: "Account Type",
      link: "/setting_account_type",
    },
    {
      th: "นโยบาย",
      en: "Policy",
      link: "/setting_policy",
    },
    {
      th: "คำถาม",
      en: "Question",
      link: "/setting_question",
    },
    {
      th: "มาร์เก็ตติ้ง",
      en: "Marketing",
      link: "/setting_marketing",
    },
    {
      th: "แบนเนอร์",
      en: "Banner",
      link: "/setting_banner",
    },
    {
      th: "ข่าว",
      en: "News",
      link: "/setting_news",
    },
    {
      th: "โปรโมชั่น",
      en: "Promotion",
      link: "/setting_promotion",
    },
    {
      th: "ตั้งค่าการถอนอัตโนมัติ",
      en: "Setup Auto Withdraw",
      link: "/setup_auto_withdraw",
    },
  ],
  mobile: [
    {
      th: "เมนู",
      en: "Menu",
    },
    {
      th: "หน้าหลัก",
      en: "Home",
      link: "/main",
    },
    {
      th: "โปรโมชั่น",
      en: "Promotion",
      link: "/promotion",
    },
    {
      th: "เกี่ยวกับเรา",
      en: "About Us",
      link: "/aboutus",
    },
    {
      th: "การเทรด",
      en: "Trading",
      link: "/trading",
    },
    {
      th: "ข่าวสาร",
      en: "News",
      link: "/news",
    },
    {
      th: "ข้อมูลตลาด",
      en: "Marketing",
      link: "/marketing",
    },
    {
      th: "แพลตฟอร์ม",
      en: "Platform",
      link: "/platform",
    },
    {
      th: "นโยบาย",
      en: "Policy",
      link: "/policy",
    },
    {
      th: "คำถามที่พบบ่อย",
      en: "Question",
      link: "/question",
    },
    {
      th: "ติดต่อเรา",
      en: "Contact",
      link: "/contact",
    },
    {
      en: "ภาษาไทย",
      th: "English",
      icon_th: th,
      icon_en: en,
    },
  ],
};
export const footBar = {
  footer: [
    {
      title_th: "ATS-Forex",
      title_en: "ATS-Forex",
      arr_body: [
        {
          th: "โปรโมชั่น",
          en: "Promotion",
          link: "/promotion",
        },
        {
          th: "ข้อมูลตลาด",
          en: "Marketing",
          link: "/marketing",
        },
        {
          th: "เกี่ยวกับเรา",
          en: "About Us",
          link: "/aboutus",
        },
        {
          th: "แพลตฟอร์ม",
          en: "Platform",
          link: "/platform",
        },
        {
          th: "การเทรด",
          en: "Trading",
          link: "/trading",
        },
        {
          th: "นโยบาย",
          en: "Policy",
          link: "/policy",
        },
        {
          th: "ข่าวสาร",
          en: "News",
          link: "/news",
        },
        {
          th: "คำถามที่พบบ่อย",
          en: "Question",
          link: "/question",
        },
      ],
    },
    {
      title_th: "ติดต่อเรา",
      title_en: "Contact",
      arr_body: [
        {
          th: "+66 000 000 000",
          en: "+66 000 000 000",
          link: "/#",
          icon: call,
        },
        {
          th: "atsforex@email.com",
          en: "atsforex@email.com",
          link: "/#",
          icon: mail,
        },
        {
          th: "ที่อยู่สำนักงาน",
          en: "ที่อยู่สำนักงาน",
          link: "/#",
          icon: location,
        },
      ],
    },
    {
      title_th: "โซเชียลมีเดีย",
      title_en: "Social Media",
      arr_body: [
        {
          link: "/#",
          icon: facebook,
        },
        {
          link: "/#",
          icon: line,
        },
        {
          link: "/#",
          icon: youtube,
        },
      ],
    },
  ],
  contact: [
    {
      title_th: "ข้อมูลติดต่อ",
      title_en: "Contact",
      arr_body: [
        {
          th: "+66 000 000 000",
          en: "+66 000 000 000",
          link: "/#",
          icon: call,
        },
        {
          th: "atsforex@email.com",
          en: "atsforex@email.com",
          link: "/#",
          icon: mail,
        },
        {
          th: "ที่อยู่สำนักงาน",
          en: "ที่อยู่สำนักงาน",
          link: "/#",
          icon: location,
        },
      ],
    },
    {
      title_th: "โซเชียลมีเดีย",
      title_en: "Social Media",
      arr_body: [
        {
          link: "/#",
          icon: facebook,
        },
        {
          link: "/#",
          icon: line,
        },
        {
          link: "/#",
          icon: youtube,
        },
      ],
    },
  ],
};
export const datePicker = {
  dashstart: [
    {
      xs: 12,
      sm: 6,
      md: 3,
      classNameGrid: "border-datepicker",
      // typography: "วันที่",
      name: "date",
    },
  ],
  edit_list: [
    {
      xs: 12,
      sm: 6,
      md: 3,
      classNameGrid: "border-datepicker pt-0",
      typography: "วันที่",
      name: "date",
    },
  ],
  list: [
    {
      xs: 12,
      sm: 3,
      // md: 3,
      classNameGrid: "border-datepicker pt-0",
      typography: "วันที่เริ่ม",
      name: "date_start",
    },
    {
      xs: 12,
      sm: 3,
      // md: 3,
      classNameGrid: "border-datepicker pt-0",
      typography: "วันที่สิ้นสุด",
      name: "date_end",
    },
  ],
  date_fin: [
    {
      xs: 12,
      sm: 3,
      // md: 3,
      classNameGrid: "border-datepicker pt-0",
      // typography: "วันที่เริ่ม",
      name: "date_start",
    },
    {
      xs: 12,
      sm: 3,
      // md: 3,
      classNameGrid: "border-datepicker pt-0",
      // typography: "วันที่สิ้นสุด",
      name: "date_end",
    },
  ],
};
export const headerTable = {
  transaction: [
    { label: "Transaction Number", align: "left" },
    { label: "Transaction", align: "left" },
    { label: "Code", align: "left" },
    { label: "Release Date", align: "left" },
    { label: "IP", align: "left" },
    { label: "Deposit/Withdrewal (Wallet)", align: "left" },
    { label: "Receive", align: "left" },
    { label: "Status", align: "left" },
  ],
  ib: [
    { label: "Transaction Number", align: "left" },
    { label: "Release Date", align: "left" },
    { label: "IP", align: "left" },
    { label: "Tranfer", align: "left" },
    { label: "Receive", align: "left" },
    { label: "Status", align: "left" },
  ],
  sale_port: [
    { label: "Trading Account Number", align: "center" },
    { label: "Account Type", align: "center" },
    { label: "Balance", align: "right" },
  ],
  member: [
    { label: "Personal Rebate", align: "left" },
    { label: "Invite", align: "left" },
  ],
  inbox: [
    { label: "Subject", align: "left" },
    { label: "Sender", align: "left" },
    { label: "Date", align: "left" },
  ],
  newmember_normal: [
    // { label: "User", align: "left" },
    { label: "User Id", align: "left" },
    { label: "Name", align: "left" },
    { label: "Wallet", align: "left" },
    { label: "MT5 Balance", align: "left" },
    { label: "IB Wallet", align: "left" },
    { label: "Mobile", align: "left" },
    { label: "Email", align: "left" },
    { label: "Sale", align: "left" },
    // { label: "Status", align: "left" },
    // { label: "Documents", align: "left" },
    // { label: "Bank", align: "left" },
    { label: "Register Date", align: "left" },
    { label: "Active", align: "left" },
    { label: "Action", align: "center" },
  ],
  newmember_sale: [
    { label: "User Id", align: "left" },
    { label: "Name", align: "left" },
    { label: "Wallet", align: "left" },
    { label: "MT5 Balance", align: "left" },
    { label: "IB Wallet", align: "left" },
    { label: "Mobile", align: "left" },
    { label: "Email", align: "left" },
    { label: "Register Date", align: "left" },
    { label: "Active", align: "left" },
    { label: "Action", align: "center" },
  ],
  newmember_admin: [
    { label: "User Id", align: "left" },
    { label: "Name", align: "left" },
    { label: "Wallet", align: "left" },
    { label: "MT5 Balance", align: "left" },
    { label: "IB Wallet", align: "left" },
    { label: "Mobile", align: "left" },
    { label: "Email", align: "left" },
    { label: "Sale", align: "left" },
    { label: "Register Date", align: "left" },
    { label: "Enable Withdraw", align: "center" },
    { label: "Active", align: "left" },
    { label: "Action", align: "center" },
  ],
  managemember_admin: [
    { label: "Name", align: "left" },
    { label: "Mobile", align: "left" },
    { label: "Email", align: "left" },
    { label: "Register Date", align: "left" },
    { label: "IB Status", align: "left" },
    { label: "Sale Namee", align: "left" },
    { label: "Change to IB", align: "left" },
    { label: "Select Sale", align: "left" },
    { label: "Network", align: "left" },
  ],
  managemember_user: [
    { label: "Name", align: "left" },
    { label: "Mobile", align: "left" },
    { label: "Email", align: "left" },
    { label: "Register Date", align: "left" },
    { label: "IB Status", align: "left" },
    { label: "Change to manager", align: "left" },
    { label: "Network", align: "left" },
  ],
  iblist: [
    { label: "User id", align: "left" },
    { label: "Manager", align: "left" },
    { label: "Email", align: "left" },
    { label: "Number of members", align: "left" },
    { label: "Sale Name", align: "left" },
    { label: "View", align: "center" },
  ],
  ib_rebate_list: [
    { label: "User id", align: "left" },
    { label: "Manager", align: "left" },
    { label: "Email", align: "left" },
    { label: "Number of members", align: "left" },
    { label: "Admin", align: "left" },
    { label: "Action", align: "center" },
  ],
  cblist: [
    { label: "Cashback ID", align: "left" },
    { label: "User", align: "left" },
    { label: "Create", align: "left" },
    { label: "Detail", align: "left" },
    { label: "Money", align: "left" },
    // { label: "Type", align: "center" },
  ],
  transactionlist: [
    { label: "Transaction ID", align: "left" },
    { label: "User", align: "left" },
    { label: "Create", align: "left" },
    { label: "Type", align: "left" },
    { label: "Detail", align: "left" },
    { label: "Money", align: "left" },
  ],
  ibTransactionlist: [
    { label: "IB Transaction ID", align: "left" },
    { label: "User", align: "left" },
    { label: "Create", align: "left" },
    { label: "Type", align: "left" },
    { label: "Detail", align: "left" },
    { label: "Money", align: "left" },
    // { label: "Lot", align: "left" },
    // { label: "Email", align: "left" },
  ],
  salelist: [
    { label: "User ID", align: "left" },
    { label: "Name", align: "left" },
    { label: "Email", align: "left" },
    { label: "Phone", align: "center" },
    { label: "Deposit", align: "right" },
    { label: "Withdrawal", align: "right" },
    { label: "Net Deposit", align: "right" },
    { label: "Agent Fee", align: "right" },
    { label: "Status", align: "left" },
    { label: "IB", align: "center" },
    { label: "Action", align: "center" },
  ],
  managerList: [
    { label: "Sale Name", align: "left" },
    { label: "Sale Manager", align: "left" },
    { label: "Deposit", align: "right" },
    { label: "Withdrawal", align: "right" },
    { label: "Net Deposit", align: "right" },
    { label: "Agent Fee", align: "center" },
    { label: "Transaction", align: "center" },
    { label: "Member", align: "center" },
    { label: "Lot", align: "center" },
  ],
  saleManagerList_manager: [
    { label: "Manager Name", align: "left" },
    // { label: "Member", align: "right" },
    { label: "Deposit", align: "right" },
    { label: "Withdrawal", align: "right" },
    { label: "Net Deposit", align: "right" },
    { label: "Agent Fee", align: "center" },
    { label: "Transaction", align: "center" },
    { label: "Member", align: "center" },
    { label: "Lot", align: "center" },
  ],
  saleManagerTeam_manager: [
    { label: "Manager Name", align: "left" },
    { label: "Member Sale", align: "right" },
    { label: "Deposit", align: "right" },
    { label: "Withdrawal", align: "right" },
    { label: "Net Deposit", align: "right" },
    { label: "Agent Fee", align: "center" },
    { label: "Transaction", align: "center" },
    { label: "Member", align: "center" },
    { label: "Lot", align: "center" },
  ],
  saleManagerList: [
    { label: "Name", align: "left" },
    { label: "Deposit", align: "right" },
    { label: "Withdrawal", align: "right" },
    { label: "Net Deposit", align: "right" },
    { label: "Agent Fee", align: "center" },
    { label: "Transaction", align: "center" },
    { label: "Member", align: "center" },
    { label: "Lot", align: "center" },
  ],
  managerMemberList: [
    { label: "Username", align: "left" },
    { label: "E-mail", align: "left" },
    { label: "Phone", align: "center" },
  ],
  saleManagerMemberList: [
    { label: "Username", align: "left" },
    { label: "E-mail", align: "left" },
    { label: "Phone", align: "center" },
    { label: "Sale", align: "left" },
  ],
  managerSaleMember: [
    { label: "Username", align: "left" },
    { label: "Phone", align: "center" },
  ],
  managerTranlist: [
    { label: "Transaction ID", align: "left" },
    { label: "User", align: "left" },
    { label: "Create", align: "left" },
    { label: "Type", align: "left" },
    { label: "Payment", align: "left" },
    { label: "Detail", align: "left" },
    { label: "Money", align: "left" },
  ],
  supporlist: [
    { label: "Ticket ID", align: "left" },
    { label: "Customer", align: "left" },
    { label: "Email", align: "left" },
    { label: "Phone", align: "left" },
    { label: "Name Ticket", align: "left" },
    { label: "Type", align: "left" },
    { label: "Status", align: "left" },
    { label: "View", align: "center" },
  ],
  fin: [
    { label: "Transaction Number", align: "left" },
    { label: "User", align: "left" },
    { label: "Email", align: "left" },
    { label: "Payment", align: "left" },
    { label: "Create", align: "left" },
    // { label: "IP", align: "left" },
    { label: "Deposit", align: "left" },
    { label: "Receive (USD)", align: "left" },
    { label: "Status", align: "left" },
    { label: "Action", align: "center" },
    // { label: "Delete", align: "center" },
    // { label: "", align: "center" },
  ],
  fin_withdraw: [
    { label: "Transaction Number", align: "left" },
    { label: "User", align: "left" },
    { label: "Email", align: "left" },
    { label: "Create", align: "left" },
    // { label: "IP", align: "left" },
    { label: "Withdrawal (USD)", align: "left" },
    { label: "Receive", align: "left" },
    { label: "Withdraw Type", align: "left" },
    { label: "Status", align: "left" },
    { label: "Action", align: "center" },
    // { label: "Delete", align: "center" },
    // { label: "", align: "center" },
  ],
  fin_normal: [
    { label: "Transaction Number", align: "left" },
    { label: "User", align: "left" },
    { label: "Email", align: "left" },
    { label: "Payment", align: "left" },
    { label: "Create", align: "left" },
    { label: "Deposit", align: "left" },
    { label: "Receive (USD)", align: "left" },
    { label: "Status", align: "left" },
  ],
  fin_withdraw_normal: [
    { label: "Transaction Number", align: "left" },
    { label: "User", align: "left" },
    { label: "Email", align: "left" },
    { label: "Create", align: "left" },
    { label: "Withdrawal (USD)", align: "left" },
    { label: "Receive", align: "left" },
    { label: "Withdraw Type", align: "left" },
    { label: "Status", align: "left" },
  ],
  fin_sale: [
    { label: "Transaction Number", align: "left" },
    { label: "User", align: "left" },
    { label: "Email", align: "left" },
    { label: "Payment", align: "left" },
    { label: "Create", align: "left" },
    { label: "Deposit", align: "left" },
    { label: "Receive (USD)", align: "left" },
    { label: "Status", align: "left" },
  ],
  fin_withdraw_sale: [
    { label: "Transaction Number", align: "left" },
    { label: "User", align: "left" },
    { label: "Email", align: "left" },
    { label: "Create", align: "left" },
    { label: "Withdrawal (USD)", align: "left" },
    { label: "Receive", align: "left" },
    { label: "Withdraw Type", align: "left" },
    { label: "Status", align: "left" },
  ],
  fin_mt4: [
    { label: "Login", align: "left" },
    { label: "Ticket", align: "left" },
    { label: "Detail", align: "left" },
    { label: "Comment", align: "left" },
    { label: "Profit", align: "left" },
    { label: "Group", align: "left" },
  ],
  exchangeRate: [
    { label: "Payment Gateway", align: "left" },
    // { label: "Spread(Deposit)", align: "left" },
    // { label: "Spread(Withdrawal)", align: "left" },
    { label: "Deposit", align: "left" },
    { label: "Withdrawal", align: "left" },
    { label: "Action", align: "left" },
  ],
  tradingAccount_admin: [
    { label: "Number", align: "left" },
    { label: "Wallet Account", align: "left" },
    { label: "Email", align: "left" },
    { label: "Balance", align: "left" },
    { label: "Account", align: "left" },
    { label: "Credit", align: "left" },
  ],
  tradingAccount: [
    { label: "Number", align: "left" },
    { label: "Wallet Account", align: "left" },
    { label: "Email", align: "left" },
    { label: "Balance", align: "left" },
    { label: "Account", align: "left" },
  ],
  broker_income: [
    { label: "ID", align: "left" },
    { label: "Detail", align: "left" },
    { label: "Money", align: "left" },
  ],
  settingWallet: [
    { label: "Name", align: "left" },
    { label: "Email", align: "left" },
    { label: "Wallet", align: "left" },
  ],
  settingDeposit: [
    // { label: "Fee", align: "left" },
    { label: "Min", align: "left" },
    { label: "Max", align: "left" },
    // { label: "Limit Transfer", align: "left" },
    { label: "Action", align: "left" },
  ],
  settingWithdrawal: [
    // { label: "Fee", align: "left" },
    { label: "Min", align: "left" },
    { label: "Max", align: "left" },
    { label: "Type", align: "left" },
    // { label: "Limit Transfer in 24 Hour", align: "left" },
    { label: "Action", align: "left" },
  ],
  settingEmail: [
    { label: "Type", align: "center" },
    { label: "Action", align: "center" },
  ],
  admin_list: [
    { label: "Username", align: "left" },
    { label: "Name", align: "left" },
    { label: "Lastname", align: "left" },
    { label: "Phone", align: "left" },
    { label: "Line ID", align: "left" },
    { label: "Role", align: "left" },
    { label: "Status", align: "center" },
    { label: "Action", align: "left" },
  ],
  report_transaction: [
    { label: "Ticket", align: "left" },
    { label: "MT", align: "left" },
    { label: "Symbol", align: "left" },
    { label: "Volume", align: "left" },
    { label: "Open Price", align: "left" },
    { label: "Close Price", align: "left" },
    { label: "Open Time", align: "left" },
    { label: "Close Time", align: "left" },
    { label: "Swap", align: "left" },
    { label: "Profit", align: "left" },
    { label: "Type", align: "left" },
  ],
  ib_child_list_nest: [
    { label: "My Lot", align: "right", value: "my_lot" },
    { label: "Commission", align: "right", value: "commission" },
    { label: "Deposit", align: "right", value: "deposit" },
    { label: "Withdraw", align: "right", value: "withdraw" },
    { label: "Net Deposit", align: "right", value: "net_deposit" },
    { label: "Net Deposit Team", align: "right", value: "net_deposit_team" },
    { label: "Team Deposit", align: "right", value: "team_deposit" },
    { label: "Team Lot", align: "right", value: "team_lot" },
    { label: "Agent Fee", align: "right", value: "agent_fee" },
  ],
  ib_child_list_header: [
    { label: "My Lot", align: "right", value: "my_lot", suffix: "Lot" },
    { label: "Commission", align: "right", value: "commission", suffix: "$" },
    { label: "Deposit", align: "right", value: "deposit", suffix: "$" },
    { label: "Withdraw", align: "right", value: "withdraw", suffix: "$" },
    { label: "Net Deposit", align: "right", value: "net_deposit", suffix: "$" },
    { label: "Net Deposit Team", align: "right", value: "net_deposit_team", suffix: "$" },
    { label: "Team Deposit", align: "right", value: "team_deposit", suffix: "$" },
    { label: "Team Lot", align: "right", value: "team_lot", suffix: "Lot" },
    { label: "Agent Fee", align: "right", value: "agent_fee", suffix: "$" },
  ],
  ib_child_list_admin: [
    { label: "Username", align: "left" },
    { label: "Email", align: "left" },
    { label: "My Lot", align: "right" },
    { label: "Commission", align: "right" },
    { label: "Deposit", align: "right" },
    { label: "Withdraw", align: "right" },
    { label: "Net Deposit", align: "right" },
    { label: "Net Deposit Team", align: "right" },
    { label: "Team Deposit", align: "right" },
    { label: "Team Lot", align: "right" },
    { label: "Agent Fee", align: "right" },
    // { label: "Action", align: "center" },
  ],
  ib_child_list: [
    { label: "Username", align: "left" },
    { label: "Email", align: "left" },
    { label: "My Lot", align: "right" },
    { label: "Commission", align: "right" },
    { label: "Deposit", align: "right" },
    { label: "Withdraw", align: "right" },
    { label: "Net Deposit", align: "right" },
    { label: "Net Deposit Team", align: "right" },
    { label: "Team Deposit", align: "right" },
    { label: "Team Lot", align: "right" },
    { label: "Agent Fee", align: "right" },
  ],
  pormotion_list: [
    { label: "รายการโปรโมชัน", align: "center" },
    { label: "วันที่เริ่มต้น", align: "center" },
    { label: "วันที่สิ้นสุด", align: "center" },
    { label: "Action", align: "center" },
  ],
  MTlist: [
    { label: "Username", align: "left" },
    { label: "E-mail", align: "left" },
    { label: "MT4/MT5", align: "center" },
    { label: "Balance", align: "right" },
    { label: "Action", align: "center" },
  ],
  report_mt5: [
    { label: "Ticket", align: "left" },
    { label: "MT5", align: "left" },
    { label: "Symbol", align: "left" },
    { label: "Volume", align: "left" },
    { label: "Open Price", align: "left" },
    { label: "Close Price", align: "left" },
    { label: "Open Time", align: "left" },
    { label: "Close Time", align: "left" },
    //  { label: "Swap", align: "left" },
    { label: "Profit", align: "left" },
    { label: "Type", align: "left" },
  ],
  ib_rebate_list_campaign: [
    { label: "Username", align: "left" },
    { label: "Action", align: "center" },
  ],
  ib_transfer: [
    { label: "No.", align: "center" },
    { label: "Username", align: "left" },
    { label: "E-mail", align: "left" },
  ],
  network_list_nest: [
    { label: "Wallet", align: "right", value: "wallet" },
    { label: "MT5 Balance", align: "right", value: "balance" },
  ],
  sale_header: [
    { label: "Deposit", align: "right", value: "sumDepositAll", suffix: "$" },
    { label: "Withdraw", align: "right", value: "sumWithdrawAll", suffix: "$" },
    // { label: "Lot", align: "right", value: "allLotAll", suffix: "$" },
  ],
  sale_header_main: [
    { label: "Deposit", align: "right", value: "sumDeposit", suffix: "$" },
    { label: "Withdraw", align: "right", value: "sumWithdraw", suffix: "$" },
  ],
  head_sale_list: [
    { label: "Deposit", align: "right", value: "deposit", suffix: "$" },
    { label: "Withdraw", align: "right", value: "withdraw", suffix: "$" },
    // { label: "Net Deposit", align: "right", value: "net_deposit", suffix: "$" },
  ],
  head_sale_list_search: [
    { label: "Deposit", align: "right", value: "sumDeposit", suffix: "$" },
    { label: "Withdraw", align: "right", value: "sumWithDraw", suffix: "$" },
  ],
};
export const textFiled = {
  add_user: [
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-2",
      placeholder: "ระบุชื่อ - นามสกุล",
      name: "fullname",
    },
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-2",
      placeholder: "ระบุอีเมล",
      name: "email",
    },
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-2",
      placeholder: "หมายเลขโทรศัพท์",
      name: "phone",
    },
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-2",
      placeholder: "ข้อความ",
      name: "detail",
      type: "textarea",
    },
  ],
  change_password: [
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Old Password",
      placeholder: "Old Password",
      name: "old_password",
      type: "password",
    },
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "New Password",
      placeholder: "New Password",
      name: "new_password",
      type: "password",
    },
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Confirm Password",
      placeholder: "Confirm Password",
      name: "confirm_password",
      type: "password",
    },
  ],
  reset_password: [
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "New Password",
      placeholder: "New Password",
      name: "new_password",
      type: "password",
    },
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Confirm Password",
      placeholder: "Confirm Password",
      name: "confirm_password",
      type: "password",
    },
  ],
  edit_banner: [
    {
      xs: 12,
      typeincon: "image",
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "File",
      title: "Main Picture",
      name: "file",
    },
  ],
  edit_exchange: [
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Payment Gateway",
      typography: "Payment Gateway",
      name: "payment_gateway",
    },
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Deposit",
      typography: "Deposit",
      name: "deposit",
    },
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Withdrawal",
      typography: "Withdrawal",
      name: "withdrawal",
    },
  ],
  setup_edit_deposit: [
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Min",
      typography: "Min",
      name: "min",
    },
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Max",
      typography: "Max",
      name: "max",
    },
  ],
  ib_account: [
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Account Name (TH)",
      placeholder: "Account Name (TH)",
      name: "name_th",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Account Name (EN)",
      placeholder: "Account Name (EN)",
      name: "name_en",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Leverage",
      placeholder: "Leverage",
      type: "number",
      name: "leverage",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Group Name",
      placeholder: "Group Name",
      name: "manager_name",
    },
  ],
  add_news: [
    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Subject",
      title: "Subject",
      name: "subject_en",
    },
    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Subject(ภาษาไทย)",
      title: "Subject(ภาษาไทย)",
      name: "subject_th",
    },
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      typeincon: "image",
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "File",
      title: "Main Picture",
      name: "file",
    },
    {
      xs: 12,
      // sm: 6,
      md: 6,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      type: "textarea",
      placeholder: "Description",
      title: "Description",
      name: "description_en",
    },
    {
      xs: 12,
      // sm: 6,
      md: 6,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      type: "textarea",
      placeholder: "Description(ภาษาไทย)",
      title: "Description(ภาษาไทย)",
      name: "description_th",
    },
    // {
    //      xs: 12,
    //      // sm: 6,
    //      // md: 3,
    //      classNameGrid: "pt-0",
    //      classNameText: "textfield-css mt-4",
    //      placeholder: "URL Youtube",
    //      title: "Link Youtube",
    //      name: "link",
    // },
  ],
  add_promotion: [
    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Promotion Name",
      title: "Promotion Name",
      name: "name_en",
    },
    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Promotion Name(ภาษาไทย)",
      title: "Promotion Name(ภาษาไทย)",
      name: "name_th",
    },
    {
      xs: 12,
      typeincon: "image",
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "File",
      title: "Main Picture(ภาษาไทย)",
      name: "promotion_image",
      language: "th",
    },
  ],
  add_promotion_image_en: [
    {
      xs: 12,
      typeincon: "image",
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "File",
      title: "Main Picture",
      name: "promotion_image_en",
      language: "en",
    },
  ],
  add_promotion_back: [
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Requirements",
      title: "Requirements",
      type: "textarea",
      minRows: 10,
      name: "requirements_en",
    },
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Requirements(ภาษาไทย)",
      title: "Requirements(ภาษาไทย)",
      type: "textarea",
      minRows: 10,
      name: "requirements_th",
    },
    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      type: "textarea",
      placeholder: "Detail",
      title: "Detail",
      name: "detail_en",
    },
    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Detail(ภาษาไทย)",
      title: "Detail(ภาษาไทย)",
      type: "textarea",
      name: "detail_th",
    },
  ],
  add_marketing: [
    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Marketing Name",
      title: "Marketing Name",
      name: "name_en",
    },
    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Marketing Name(ภาษาไทย)",
      title: "Marketing Name(ภาษาไทย)",
      name: "name_th",
    },
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      typeincon: "image",
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "File",
      title: "Main Picture",
      name: "file",
    },
    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Detail",
      type: "textarea",
      title: "Detail",
      name: "detail_en",
    },
    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Detail(ภาษาไทย)",
      type: "textarea",
      title: "Detail(ภาษาไทย)",
      name: "detail_th",
    },
  ],
  add_service: [
    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Service Name",
      title: "Service Name",
      name: "name_en",
    },
    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Service Name(ภาษาไทย)",
      title: "Service Name(ภาษาไทย)",
      name: "name_th",
    },
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      typeincon: "image",
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "File",
      title: "Main Picture",
      name: "file",
    },
    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Detail",
      title: "Detail",
      name: "detail_en",
    },
    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Detail(ภาษาไทย)",
      title: "Detail(ภาษาไทย)",
      name: "detail_th",
    },
  ],
  add_account_type: [
    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Subject",
      title: "Subject",
      name: "name_en",
    },
    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      placeholder: "Subject(ภาษาไทย)",
      title: "Subject(ภาษาไทย)",
      name: "name_th",
    },
  ],
  account_type_list: [
    {
      xs: 6,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      typeincon: "sb-title",
      placeholder: "Select Type",
      title: "Type",
      label: "Select Type",
      name: "type",
      select: true,
      selectname: "menuItem",
      typography: "Select Type",
      // type: "text",
    },
    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      typeincon: "sb-title",
      placeholder: "Leverage",
      title: "Default leverage",
      name: "leverage",
      type: "number",
    },
    // {
    //      xs: 6,
    //      // sm: 6,
    //      // md: 3,
    //      classNameGrid: "pt-0",
    //      classNameText: "textfield-css mt-4",
    //      typeincon: "sb-title",
    //      placeholder: "Margin Call",
    //      title: "Margin Call",
    //      name: "margin_call",
    //      type: "number",
    // },

    // {
    //      xs: 6,
    //      // sm: 6,
    //      // md: 3,
    //      classNameGrid: "pt-0",
    //      classNameText: "textfield-css mt-4",
    //      typeincon: "sb-title",
    //      placeholder: "Stop Out",
    //      title: "Stop Out",
    //      name: "stop_out",
    //      type: "number",
    // },
    // {
    //      xs: 6,
    //      // sm: 6,
    //      // md: 3,
    //      classNameGrid: "pt-0",
    //      classNameText: "textfield-css mt-4",
    //      typeincon: "sb-title",
    //      placeholder: "Spread",
    //      title: "Spread",
    //      name: "spread",
    //      type: "text",
    // },
    // {
    //      xs: 6,
    //      // sm: 6,
    //      // md: 3,
    //      classNameGrid: "pt-0",
    //      classNameText: "textfield-css mt-4",
    //      typeincon: "sb-title",
    //      placeholder: "Commission",
    //      title: "Commission",
    //      name: "commission",
    //      type: "number",
    // },
    // {
    //      xs: 6,
    //      // sm: 6,
    //      // md: 3,
    //      classNameGrid: "pt-0",
    //      classNameText: "textfield-css mt-4",
    //      typeincon: "sb-title",
    //      placeholder: "Minimum Lot",
    //      title: "Minimum Lot",
    //      name: "minimum_lot",
    //      type: "number",
    // },
    // {
    //      xs: 6,
    //      // sm: 6,
    //      // md: 3,
    //      classNameGrid: "pt-0",
    //      classNameText: "textfield-css mt-4",
    //      typeincon: "sb-title",
    //      placeholder: "Maximum Lot",
    //      title: "Maximum Lot",
    //      name: "maximum_lot",
    //      type: "number",
    // },
    // {
    //      xs: 6,
    //      // sm: 6,
    //      // md: 3,
    //      classNameGrid: "pt-0",
    //      classNameText: "textfield-css mt-4",
    //      typeincon: "sb-title",
    //      placeholder: "Maximum Order",
    //      title: "Maximum Order",
    //      name: "maximum_order",
    //      type: "number",
    // },
    // {
    //      xs: 6,
    //      // sm: 6,
    //      // md: 3,
    //      classNameGrid: "pt-0",
    //      classNameText: "textfield-css mt-4",
    //      typeincon: "sb-title",
    //      placeholder: "Commission Per Lot",
    //      title: "Commission Per Lot",
    //      name: "commission_per_lot",
    //      type: "number",
    // },

    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      typeincon: "sb-title",
      placeholder: "MT4 manager name",
      title: "MT4 manager name",
      name: "manager_name",
      type: "text",
    },
    {
      xs: 6,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-4",
      typeincon: "sb-title",
      placeholder: "Min Deposit",
      title: "Min Deposit",
      name: "min_deposit",
      type: "number",
    },
  ],
  login: [
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css-login mt-2",
      placeholder: "User Email",
      name: "username",
      icon: username,
    },
    {
      xs: 12,
      // sm: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css-login-password mt-2",
      placeholder: "Password",
      name: "password",
      icon: password,
      type: "password",
    },
  ],
  register: [
    {
      xs: 12,
      classNameGrid: "typography-css-login pt-0",
      classNameText: "textfield-css-login mt-2",
      typography: "Name",
      placeholder: "Name",
      name: "name",
    },
    {
      xs: 12,
      classNameGrid: "typography-css-login pt-0",
      classNameText: "textfield-css-login mt-2",
      typography: "Lastname",
      placeholder: "Lastname",
      name: "lastname",
    },
    {
      xs: 12,
      classNameGrid: "typography-css-login pt-0",
      classNameText: "textfield-css-login mt-2",
      typography: "Email",
      placeholder: "User Email",
      name: "username",
    },
    // {
    //      xs: 12,
    //      classNameGrid: "typography-css-login pt-0",
    //      classNameText: "textfield-css-login mt-2",
    //      typography: "Confirm Email",
    //      placeholder: "Trainer Email",
    //      name: "trainer_email",
    // },
    // {
    //      xs: 12,
    //      classNameGrid: "typography-css-login pt-0",
    //      classNameText: "textfield-css-login mt-2",
    //      typography: "Phone Number",
    //      placeholder: "Phone Number",
    //      name: "phone",
    //      type: "number",
    // },
    {
      xs: 12,
      classNameGrid: "typography-css-login pt-0",
      classNameText: "textfield-css-login-password mt-2",
      typography: "Password",
      placeholder: "Password",
      name: "password",
      type: "password",
      // icon: password,
    },
    {
      xs: 12,
      classNameGrid: "typography-css-login pt-0",
      classNameText: "textfield-css-login-password mt-2",
      typography: "Confirm Password",
      placeholder: "Confirm Password",
      name: "confirm_password",
      type: "password",
      // icon: password,
    },
  ],
  editProfile: [
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Name",
      placeholder: "Name",
      name: "name",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Lastname",
      placeholder: "Lastname",
      name: "lastname",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "ID Card/Passport",
      placeholder: "ID Card/Passport",
      name: "idcard",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Location",
      placeholder: "Location",
      name: "location",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Address 1",
      placeholder: "Address 1",
      name: "address",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Mobile Phone",
      placeholder: "080-00-0000",
      name: "phone",
      type: "number",
    },
  ],
  resetPassword: [
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Password",
      placeholder: "Password",
      name: "password",
      type: "password",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "New Password",
      placeholder: "New Password",
      name: "new_password",
      type: "password",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Repeat Password",
      placeholder: "Repeat Password",
      name: "pepeat_password",
      type: "password",
    },
  ],
  add_admin: [
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Role",
      placeholder: "Select Role",
      name: "role",
      // type: "text",
      select: true,
      selectname: "menuItem",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Username",
      placeholder: "Username",
      name: "username",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      type: "password",
      typography: "Password",
      placeholder: "Password",
      name: "password",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      type: "password",
      typography: "Confirm Password",
      placeholder: "Confirm Password",
      name: "password_confirm",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Name",
      placeholder: "Name",
      name: "name",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Last Name",
      placeholder: "Last Name",
      name: "lastname",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Phone",
      placeholder: "Phone",
      name: "phone",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Line ID",
      placeholder: "Line ID",
      name: "line_id",
    },
  ],
  edit_admin: [
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Name",
      placeholder: "Name",
      name: "name",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Last Name",
      placeholder: "Last Name",
      name: "lastname",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Phone",
      placeholder: "Phone",
      name: "phone",
    },
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Line ID",
      placeholder: "Line ID",
      name: "line_id",
    },
  ],
  select_admin: [
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      typography: "Sale",
      placeholder: "Select Sale",
      name: "sale",
      select: true,
      selectname: "menuItem",
    },
  ],
  add_remark: [
    {
      xs: 12,
      // sm: 6,
      md: 12,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-2",
      title: "Remark",
      placeholder: "Remark",
      name: "remark",
      type: "textarea",
    },
  ],
  change_ib: [
    {
      typography: "Member",
      xs: 12,
      // sm: 6,
      md: 12,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-2",
      placeholder: "Member",
      name: "connect_user",
      select: true,
      selectname: "menuItem",
    },
  ],
  radio_doc: [
    {
      classNameGrid: "pt-0 pb-0",
      value: 1,
      name: "type",
      label: "ID card",
      checked: 1,
    },
    {
      classNameGrid: "pt-0 pb-0",
      value: 2,
      name: "type",
      label: "Driving license",
      checked: 2,
    },
    {
      classNameGrid: "pt-0 pb-0",
      value: 3,
      name: "type",
      label: "Passport",
      checked: 3,
    },
  ],
  search_td: [
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      placeholder: "Search",
      name: "search_text_td",
      type: "text",
    },
  ],
  search_rp: [
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      placeholder: "Search",
      name: "mt4",
      type: "text",
      select: true,
      selectname: "menuItem",
    },
  ],
  search_ib: [
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      placeholder: "Search",
      name: "search_text_ib",
      type: "text",
    },
  ],
  search_inbox: [
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      placeholder: "Search",
      name: "search_text",
      type: "text",
    },
  ],
  search_text: [
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      placeholder: "Search",
      name: "search_text",
      type: "text",
    },
  ],
  search_text_MT4: [
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      placeholder: "Search User",
      name: "search_text",
      type: "text",
    },
  ],
  search_text_dashboard: [
    {
      xs: 6,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      placeholder: "Status",
      name: "select_search_text",
      type: "text",
      select: true,
      selectname: "menuItem",
    },
    {
      xs: 6,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      placeholder: "Search",
      name: "search_text",
      type: "text",
    },
  ],
  search_text_sale_list: [
    {
      xs: 6,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      placeholder: "Status",
      name: "sale_select",
      type: "text",
      select: true,
      selectname: "menuItem",
    },
  ],
  search_text_manager: [
    {
      xs: 6,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      placeholder: "Status",
      name: "sale_select",
      type: "text",
      select: true,
      selectname: "menuItem",
    },
  ],
  search_text_manage: [
    {
      xs: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      placeholder: "Search",
      name: "search_text",
      type: "text",
    },
  ],
  search_fin: [
    {
      xs: 12,
      sm: 12,
      classNameGrid: "typography-profile pt-0",
      classNameText: "textfield-profile mt-2",
      placeholder: "Username",
      name: "search_text",
      type: "text",
    },
  ],
  faq: [
    {
      xs: 12,
      md: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-2",
      title: "Question",
      placeholder: "Question",
      name: "faq_question_en",
    },
    {
      xs: 12,
      md: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-2",
      title: "Question (ภาษาไทย)",
      placeholder: "Question (ภาษาไทย)",
      name: "faq_question_th",
    },
  ],
  faq_edit: [
    {
      xs: 12,
      // sm: 6,
      md: 6,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-2",
      title: "Question",
      placeholder: "Question",
      name: "question_en",
    },
    {
      xs: 12,
      // sm: 6,
      md: 6,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-2",
      title: "Question (ภาษาไทย)",
      placeholder: "Question (ภาษาไทย)",
      name: "question_th",
    },
    {
      xs: 12,
      // sm: 6,
      md: 6,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-2",
      title: "Answer",
      placeholder: "Answer",
      name: "answer_en",
      type: "textarea",
    },
    {
      xs: 12,
      // sm: 6,
      md: 6,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-2",
      title: "Answer (ภาษาไทย)",
      placeholder: "Answer (ภาษาไทย)",
      name: "answer_th",
      type: "textarea",
    },
  ],
  policy: [
    {
      xs: 12,
      md: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-2",
      title: "Subject",
      placeholder: "Subject",
      name: "subject_en",
    },
    {
      xs: 12,
      md: 6,
      // md: 3,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-2",
      title: "Subject (ภาษาไทย)",
      placeholder: "Subject (ภาษาไทย)",
      name: "subject_th",
    },
  ],
  policy_modal: [
    {
      xs: 12,
      // sm: 6,
      md: 6,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-2",
      title: "Detail",
      placeholder: "Detail",
      name: "detail_en",
    },
    {
      xs: 12,
      // sm: 6,
      md: 6,
      classNameGrid: "pt-0",
      classNameText: "textfield-css mt-2",
      title: "Detail (ภาษาไทย)",
      placeholder: "Detail (ภาษาไทย)",
      name: "detail_th",
    },
  ],
};
export const nextsInput = {
  nextinputlist: [
    {
      name: "code",
      next: "case",
    },
    {
      name: "case",
      next: "money",
    },
    {
      name: "money",
      next: "submit",
    },
  ],
  nextinputuser: [
    {
      name: "code",
      next: "name",
    },
    {
      name: "name",
      next: "id_card",
    },
    {
      name: "id_card",
      next: "bookbank",
    },
    {
      name: "bookbank",
      next: "submit",
    },
  ],
};

export const StringSet = {
  market: {
    sidebar: {
      title: "Menu",
      pageName: "market",
      menuItem: [
        { manuTitle: "All Market", link: "/setting_marketing" },
        { manuTitle: "Add Market", link: "/setting_manage_market/add" },
      ],
    },
    header: { title: "Add Marketing" },
    headerEdit: { title: "Edit Marketing" },
    link: "/setting_manage_market/add",
  },
  news: {
    sidebar: {
      title: "Menu",
      pageName: "news",
      menuItem: [
        { manuTitle: "All News", link: "/setting_news" },
        { manuTitle: "Add News", link: "/setting_manage_news/add" },
      ],
    },
    header: { title: "Add News" },
    headerEdit: { title: "Edit News" },
    link: "/setting_manage_news/add",
  },
  promotion: {
    sidebar: {
      title: "Menu",
      pageName: "promotion",
      menuItem: [
        { manuTitle: "All Promotion", link: "/setting_promotion" },
        { manuTitle: "Add Promotion", link: "/setting_manage_promotion/add" },
      ],
    },
    header: { title: "Add Promotion" },
    headerEdit: { title: "Edit Promotion" },
    link: "/setting_manage_promotion/add",
  },
  account_type: {
    sidebar: {
      title: "Menu",
      pageName: "account_type",
      menuItem: [
        { manuTitle: "Account Type", link: "/setting_account_type" },
        {
          manuTitle: "Add Account Type",
          link: "/setting_manage_account_type/add",
        },
      ],
    },
    header: { title: "Add Account Type" },
    headerEdit: { title: "Edit Account Type" },
    link: "/setting_manage_account_type/add",
  },
  service: {
    sidebar: {
      title: "Menu",
      pageName: "service",
      menuItem: [
        { manuTitle: "Service", link: "/setting_service" },
        { manuTitle: "Add Service", link: "/setting_manage_service/add" },
      ],
    },
    header: { title: "Add Service" },
    headerEdit: { title: "Edit Service" },
    link: "/setting_manage_service/add",
  },
  faq: {
    sidebar: {
      title: "Menu",
      pageName: "setting_question",
      menuItem: [
        { manuTitle: "FAQ", link: "/setting_question" },
        { manuTitle: "Add FAQ", link: "/setting_manage_question/add" },
      ],
    },
    header: { title: "Add FAQ" },
    headerEdit: { title: "Edit FAQ" },
    link: "/setting_manage_question/add",
  },
  policy: {
    sidebar: {
      title: "Menu",
      pageName: "setting_policy",
      menuItem: [
        {
          manuTitle: "Policy",
          link: "/setting_policy",
          submanu_title: "Add Policy",
          sub_link: "/setting_manage_policy/add",
        },
        // { manuTitle: "Add Policy", link: "/setting_manage_policy/add" },
      ],
    },
    header: { title: "Add Policy" },
    headerEdit: { title: "Edit Policy" },
    link: "/setting_manage_policy/add",
  },
  sideBar: [
    { manuTitle: "Wallet Control", link: "/setting_wallet_control" },
    { manuTitle: "Setup Deposit", link: "/setting_setup_deposit" },
    { manuTitle: "Setup Withdrawal", link: "/setting_setup_withdrawal" },
    // { manuTitle: "Setup System", link: "/not-found" },
    // { manuTitle: "Setup Group Leverage", link: "/not-found" },
    // { manuTitle: "Service", link: "/setting_service" },
    {
      manuTitle: "Account Type",
      link: "/setting_account_type",
      submanu_title: "Add Account Type",
      sub_link: "/setting_manage_account_type/add",
    },
    // { manuTitle: "Policy", link: "/setting_policy", submanu_title: "Add Policy", sub_link: "/setting_manage_policy/add" },
    // { manuTitle: "FAQ", link: "/setting_question", submanu_title: "Add FAQ", sub_link: "/setting_manage_question/add" },
    // { manuTitle: "Banner", link: "/setting_banner" },
    // { manuTitle: "Market", link: "/setting_marketing", submanu_title: "Add Market", sub_link: "/setting_manage_market/add" },
    // { manuTitle: "News", link: "/setting_news", submanu_title: "Add News", sub_link: "/setting_manage_news/add" },
    // { manuTitle: "Promotion", link: "/setting_promotion", submanu_title: "Add Promotion", sub_link: "/setting_manage_promotion/add" },
    {
      manuTitle: "Admin",
      link: "/admin",
      submanu_title: "Add Admin",
      sub_link: "/admin/add",
    },
    {
      manuTitle: "Sale Team",
      link: "/setting_sale_manager",
    },
    { manuTitle: "Withdraw", link: "/setting_withdraw" },
    { manuTitle: "Setup Auto Withdraw", link: "/setup_auto_withdraw" },
    // { manuTitle: "Setting E-mail", link: "/setting_email" },
  ],
};

export const header_csv = {
  headers_deposit: [
    { label: "Transaction Number", key: "transaction_id" },
    { label: "User Id", key: "user_id" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Create", key: "create" },
    { label: "Deposit (BAHT)", key: "deposit" },
    { label: "Receive (USD)", key: "receive" },
    { label: "Status", key: "status" },
  ],
  headers_withdraw: [
    { label: "Transaction Number", key: "transaction_id" },
    { label: "User Id", key: "user_id" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Create", key: "create" },
    { label: "Withdrawal (USD)", key: "withdraw" },
    { label: "Receive (BAHT)", key: "receive" },
    { label: "Status", key: "status" },
  ],
  headers_mt4: [
    { label: "Login", key: "LOGIN" },
    { label: "Ticket", key: "TICKET" },
    { label: "Detail", key: "detail" },
    { label: "Comment", key: "COMMENT" },
    { label: "Profit", key: "PROFIT" },
    { label: "Group", key: "GROUP" },
  ],
  trading_account: [
    { label: "MT4 Number", key: "mt4" },
    { label: "User Id", key: "user_id" },
    { label: "Wallet Account", key: "namelastname" },
    { label: "Email", key: "email" },
    { label: "Balance", key: "balance" },
    { label: "Account", key: "account_type_name" },
  ],
};

export const ColorQRS = [
  {
    color: "#4589C6",
  },
];

export const role_label = {
  admin: { label: "Admin" },
  sale: { label: "Sale" },
  sale_manager: { label: "Sale Manager" },
  normal: { label: "Normal" },
  customer_officer: { label: "Customer Officer" },
};
