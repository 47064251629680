import { Container, Grid } from "@material-ui/core";
import HeaderTypography from "components/HeaderTypography/HeaderTypography";
import Loading from "components/Loading/Loading";
import { StringSet } from "functions/Static";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { GET, GETSALEMANAGER } from "service";
import ListSaleTeam from "./Components/ListSaleTeam";
import Sidebar from "./Components/Sidebar";

class SettingSaleManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: false,
        };
    }

    componentDidMount() {
        this.getSaleManager();
    }

    async getSaleManager() {
        try {
            this.setState({ loading: true });
            let res = await GET(GETSALEMANAGER);
            if (res.success) {
                this.setState({ data: res.result.map((e) => ({ ...e, expand: 0 })) });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            this.setState({ loading: false });
            console.log(`error`, error);
        }
    }

    onClickEdit = (id, name, lastname) => {
        this.props.history.push(`/setting_sale_manager/edit/${id}`);
        this.props.history.push({
            pathname: `/setting_sale_manager/edit/${id}`,
            state: { username: `${name} ${lastname}` },
        });
    };

    updateDataList = (id, show) => {
        const { data } = this.state;
        let newData = data.map(item => {
            return item.admin_id === id ? { ...item, expand: show === 0 ? 1 : 0 } : item
        })
        this.setState({ data: newData });
    }

    render() {
        const { data, loading, state } = this.state;
        return (
            <Container maxWidth="xl">
                <Loading open={loading} />
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Sidebar title="Menu" pageName="account_type" data={StringSet.account_type.sidebar} />
                    </Grid>
                    <Grid item container spacing={2} direction="row" xs={9}>
                        <Grid item xs={12}>
                            <HeaderTypography title={"Sale Manager"} />
                            {data &&
                                <ListSaleTeam
                                    state={state}
                                    data={data}
                                    onClickEdit={this.onClickEdit}
                                    updateDataList={this.updateDataList}
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default withRouter(SettingSaleManager);
