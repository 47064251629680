import { Container } from "@material-ui/core";
import { headerTable } from "functions/Static";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { GETTRANSACTIONBYSALE, GETTRANSACTIONSALEMANAGER, POST } from "service";
import swal from "sweetalert2";
import ManagerTranCard from "./components/ManagerTranCard";
import Loading from "components/Loading/Loading";

export class ManagerTransaction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            language: localStorage.getItem("language"),
            loading: false,
            data: [],
            page: 1,
            profile: JSON.parse(this.props.match.params.detail),
            // profile_name: this.props.location.state.username,
            // date_start: this.props.location.state.date_start,
            // date_end: this.props.location.state.date_end
        };
    }
    componentDidMount = () => {
        this.getTransactionBySale();
    };

    getTransactionBySale = async () => {
        this.setState({ loading: true });
        const { profile } = this.state;

        try {
            const api_ = profile.type === 'saleManagerTeam' ? GETTRANSACTIONSALEMANAGER : GETTRANSACTIONBYSALE;
            const params = { admin_id: profile.id, date_start: profile.date_start, date_end: profile.date_end };

            let res = await POST(api_, params);

            if (res.success) {
                this.setState({
                    data: res.result,
                    loading: false
                });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ",
                    text: res.message,
                    icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            console.error(error); // Log the error for debugging
            swal.fire({
                title: "ไม่สำเร็จ",
                text: "An unexpected error occurred. Please try again.",
                icon: "warning",
                showConfirmButton: true
            });
        }
    };

    handleChangePage = (e, value) => {
        this.setState({ page: value });
    };

    render() {
        const { loading, language, data, page, profile } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <Loading open={loading} />
                <ManagerTranCard
                    language={language}
                    data={data}
                    head={headerTable.managerTranlist}
                    page={page}
                    handleChangePage={this.handleChangePage}
                    profile_name={profile.username}
                />
            </Container>
        );
    }
}

export default withRouter(ManagerTransaction);
