import { Grid, Stack, Typography } from "@material-ui/core";
import GridTableLot from "./GridTableLot";
import moment from "moment";

const SaleManagerLotCard = (props) => {
    const {
        language,
        data,
        profile,
    } = props;

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                    {profile.name}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {/* {language === "th" ? "รายการ Lot" : "Lot List"} */}
                <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000", mt: 3 }}>วันที่เริ่ม {moment(profile.date_start).format("DD/MM/YYYY")} - วันที่สิ้นสุด {moment(profile.date_end).format("DD/MM/YYYY")}</Typography>
            </Grid>
            <Grid item xs={12}>
                <GridTableLot
                    body={data}
                />
            </Grid>
        </Grid>
    );
};

export default SaleManagerLotCard;
