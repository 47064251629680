import { Alert, Button, Grid, Snackbar, Stack, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { GridTextField } from "components";
import { headerTable, textFiled } from "functions/Static";
import DatePicker from "react-date-picker";
import DetailTaps from "../DetailTaps/DetailTaps";
import GridPaperIB from "./GridPaperIB";
import GridTableIBMemberNest from "./GridTableIBMemberNest";
import CsvDownload from "react-csv-downloader";

const TableIBNest = (props) => {
    const role = localStorage.getItem("role");
    const language = localStorage.getItem("language");
    const count = Math.ceil(props.data_network.length > 0 && props.data_network.length / 5);
    const setPage = props.page - 1;
    const dataList = props.data_network.slice(setPage * 5, props.page * 5);
    const columns = [
        {
            id: 'level',
            displayName: "Level"
        },
        {
            id: 'ib_status',
            displayName: "IB Status"
        },
        {
            id: 'username',
            displayName: "Username"
        },
        {
            id: 'email',
            displayName: "Email"
        },
        {
            id: 'my_lot',
            displayName: "MyLot"
        },
        {
            id: 'commission',
            displayName: "Commission"
        },
        {
            id: 'deposit',
            displayName: "Deposit"
        },
        {
            id: 'withdraw',
            displayName: "Withdraw"
        },
        {
            id: 'net_deposit',
            displayName: "Net Deposit"
        },
        {
            id: 'net_deposit_team',
            displayName: "Net Deposit Team"
        },
        {
            id: 'team_deposit',
            displayName: "Team Deposit"
        },
        {
            id: 'team_withdraw',
            displayName: "Team Withdraw"
        },
        {
            id: 'team_lot',
            displayName: "Team Lot"
        },
        {
            id: 'agent_fee',
            displayName: "Agent Fee"
        },
    ];
    return (
        <Grid container spacing={2}>
            <Grid xs={12} item>
                <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>IB Network</Typography>
            </Grid>
            {props.data_header_profile.map((e, i) =>
                <Grid key={i + 1} item xs={12}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600 }}>
                        {e.name} {e.lastname} ( {props.data_header.count_team ? props.data_header.count_team : 0} )
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12}>
                <Stack direction="row" alignItems="center" mt={2}>
                    <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2, display: { xs: "none", md: "block" } }}>
                        Search
                    </Typography>
                    <Grid container xs={12} md={12} lg={10} sx={{ mr: 2 }} spacing={1}>
                        <Grid item xs={12} md={3}>
                            <DatePicker className={"date-picker"} onChange={props.handleDateStartChange} value={props.state.date_start} format="dd/MM/yyyy" />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <DatePicker className={"date-picker"} onChange={props.handleDateEndChange} value={props.state.date_end} format="dd/MM/yyyy" />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "#4589C6" }}
                                sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "none", md: "block" } }}
                                onClick={props.submitSearch}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        props.submitSearch();
                                    }
                                }}
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ maxWidth: 50, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "block", md: "none" } }}
                        onClick={props.submitSearch}
                    >
                        <SearchIcon />
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                {/* <GridPaperIB data={props.data_header} /> */}
                <GridPaperIB data={props.data_header} header={headerTable.ib_child_list_header} />
            </Grid>
            <DetailTaps setTap={props.setTap} />
            {props.tap_value === 2 ? (
                <>
                    <Grid item xs={6} display="flex" justifyContent="flex-start" alignItems="center" >
                        <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>Network</Typography>
                    </Grid>
                    <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
                        <CsvDownload
                            style={{ color: "#fff", textDecoration: "none" }}
                            filename={`IB_Report_` + new Date().toLocaleString('en-GB')}
                            extension='.csv'
                            columns={columns}
                            datas={props.getReportIBNest}
                        >
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "#4589C6" }}
                                sx={{ maxWidth: 150, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                            >
                                export data
                            </Button>
                        </CsvDownload>
                    </Grid>
                    <Grid xs={12} item>
                        <GridTableIBMemberNest
                            data={props.data_network}
                            nav_sub_ib={props.nav_sub_ib}
                            count_network={props.count_network}
                            state={props.state}
                            handleChangeText={props.handleChangeText}
                            dividend_text={props.dividend_text}
                            tap_value={props.tap_value}
                            edit_disabled={props.edit_disabled_ib}
                            userId={props.userId}
                            handleDateStartChange={props.handleDateStartChange}
                            handleDateEndChange={props.handleDateEndChange}
                            submitSearch={props.submitSearch}
                            page={props.page}
                            handleChangePage={props.handleChangePage}
                            head={headerTable.ib_child_list_nest}
                            body={props.data_network}
                            // body={dataList}
                            count={count}
                            handleDataNest={props.handleDataNest}
                            language={language}
                            getAllIBNest={props.getAllIBNest}
                            getIBNest={props.getIBNest}
                            handleShowAll={props.handleShowAll}
                            showAll={props.showAll}   
                        />
                    </Grid>
                </>
            ) : props.tap_value === 3 ? (
                <>
                    <Grid container justifyContent={"space-between"} item>
                        <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>Account</Typography>
                    </Grid>
                    <Grid item xs={12} container spacing={2} mb={5}>
                        <GridTextField data={textFiled.ib_account} state={props.state} handleChange={props.handleChangeText} />
                    </Grid>
                    <Grid item container justifyContent={"center"}>
                        <Button className="btn-style" variant="contained" style={{ backgroundColor: "#4589C6" }} sx={{ maxWidth: 120 }} onClick={props.onClickEditAccount}>
                            Edit
                        </Button>
                    </Grid>
                </>
            ) : null}
            <Snackbar open={props.download_success} autoHideDuration={6000} onClose={props.handleCloseAlert}>
                <Alert
                    onClose={props.handleCloseAlert}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Download report is a success.
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default TableIBNest;
