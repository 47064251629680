import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { makeStyles } from "@material-ui/styles";
import { headerTable, role_label } from "functions/Static";
import React from "react";

const TableListAdmin = (props) => {
  const { onClickDelete, onClickOpen, onClickBan, onClickUnbanAccount, handleOpenModal } = props;
  const count = Math.ceil(props.body.length > 0 && props.body.length / 10);
  const setPage = props.page - 1;
  const dataList = props.body.slice(setPage * 10, props.page * 10);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [id, setId] = React.useState(null);
  const [username, setUsername] = React.useState(null);
  const [statusBan, setStatusBan] = React.useState(null);


  const handleClick = (event, id, user, status) => {
    setId(id);
    setUsername(user)
    setStatusBan(status)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid className="table-btn">
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headerTable.admin_list.map((el, i) => {
                return (
                  <TableCell key={i} align={el.align || "left"}>
                    <Typography noWrap>{el.label}</Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((el, i) => {
              return (
                <TableRow
                  hover
                  key={i}
                  className={i % 2 !== 0 ? "bg-table" : ""}
                >
                  <TableCell>
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                      {el.admin || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                      {el.name || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                      {el.lastname || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                      {el.phone || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                      {el.line_id || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                      {role_label[el.role].label || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {
                      el.status === 1 ? (
                        <Typography noWrap className={classes.fontActive} sx={{ backgroundColor: "#82C729" }}>
                          Active
                        </Typography>
                      ) : el.status === 2 ? (
                        <Typography noWrap className={classes.fontActive} sx={{ backgroundColor: "#FF0000" }}>
                          Ban
                        </Typography>
                      ) : ("-")
                    }
                  </TableCell>
                  <TableCell>
                    <Typography
                      noWrap
                      sx={{ fontSize: 20, fontWeight: 600, color: "#184B8E" }}
                    >
                      <Button
                        onClick={(event) => handleClick(event, el.admin_id, `${el.name} ${el.lastname}`, el.status)}
                        className={classes.button}
                      >
                        <MoreHorizIcon />
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem
                          onClick={() => {
                            handleOpenModal(id, username);
                            handleClose();
                          }}
                        >
                          Change Password
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            onClickOpen(id);
                            handleClose();
                          }}
                        >
                          Edit
                        </MenuItem>
                        {statusBan === 1 ?
                          <MenuItem
                            onClick={() => {
                              onClickBan(id, username);
                              handleClose();
                            }}
                          >
                            Ban
                          </MenuItem>
                          :
                          <MenuItem
                            onClick={() => {
                              onClickUnbanAccount(id, username);
                              handleClose();
                            }}
                          >
                            Unban
                          </MenuItem>
                        }
                        <MenuItem
                          onClick={() => {
                            onClickDelete(id, username);
                            handleClose();
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justifyContent="center" mt={5}>
        {dataList.length === 0 && (
          <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
            {props.language === "th"
              ? "ไม่มีข้อมูลในตาราง"
              : "No data available in table"}
          </Typography>
        )}
        {dataList.length > 0 && (
          <Pagination
            count={count}
            page={props.page}
            onChange={props.handleChangePage}
            variant="outlined"
            color="secondary"
          />
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: 320,
  },
  button: {
    "&:hover": {
      backgroundColor: "none",
    },
  },
  fontActive: {
    fontSize: 15,
    color: "#fff",
    padding: 8,
    borderRadius: 10,
  },
});

export default TableListAdmin;
