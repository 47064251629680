import { Container } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import moment from "moment";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { GEHEADSALE, GET, GETSALEAGENTALLSALE, GETSALEAGENTBYSALEID, GETSALELOTALLADMIN, GETSALELOTBYSEARCHADMIN, GETSALEMANAGERAGENTALL, GETSALEMANAGERTEAMAGENT, GETUSERSALEALLNOLOT, GETUSERSALEMANAGERALLNOLOT, POST } from "service";
import swal from "sweetalert2";
import ManagerCard from "./components/ManagerCard";

export class Manager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            language: localStorage.getItem("language"),
            date_start: new Date(),
            date_end: new Date(),
            header: [],
            data_tbl: [],
            data_sale_manager_tbl: [],
            data_sale_manager_team_tbl: [],
            data_header: [],
            search_text: '',
            data_lot_all: [],
            data_lot_search: [],
            showAll: false,
            showAllSaleManager: false,
            search_check: false,
            loading: false,
            pageSale: 1,
            pageSaleManager: 1,
            pageSaleManagerTeam: 1,
        };
    }
    componentDidMount = () => {
        this.getUserSale();
        this.getUserSaleHead();
        this.getSaleLotAll();
        this.getSaleLotBySearch();
    };

    getUserSaleHead = async () => {
        // this.setState({ loading: true });
        try {
            let res = await GET(GEHEADSALE);
            if (res.success) {
                this.setState({ header: res.result })
                // this.setState({ loading: false });
            } else {
                // this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            // this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    };

    getUserSale = async () => {
        const { date_start, date_end, search_text } = this.state;
        let start = moment(date_start).format("YYYY-MM-DD");
        let end = moment(date_end).format("YYYY-MM-DD");
        // this.setState({ loading: true });
        try {
            let res = await POST(GETUSERSALEALLNOLOT, { date_start: start, date_end: end, text_search: search_text });
            if (res.success) {
                const { data_tbl, data_header, data_sale_manager_tbl, data_sale_manager_team_tbl } = res.result
                this.setState({ data_tbl, data_sale_manager_tbl, data_sale_manager_team_tbl, data_header })
                // this.setState({ loading: false });
            } else {
                // this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            // this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    };

    getSaleLotAll = async () => {
        const { language } = this.state;
        // this.setState({ loading: true });
        try {
            let res = await POST(GETSALELOTALLADMIN, { lang: language });
            if (res.success) {
                this.setState({ data_lot_all: res.result })
                // this.setState({ loading: false });
            }
        } catch (error) {
            console.log("error", error);
            // this.setState({ loading: false });
        }
    };

    getSaleLotBySearch = async () => {
        this.setState({ loading: true });
        const { language, date_start, date_end, search_text } = this.state;
        let start = moment(date_start).format("YYYY-MM-DD");
        let end = moment(date_end).format("YYYY-MM-DD");
        try {
            let res = await POST(GETSALELOTBYSEARCHADMIN, { lang: language, date_start: start, date_end: end, text_search: search_text });
            if (res.success) {
                this.setState({ data_lot_search: res.result })
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
        }
    };

    getSaleAgentBySale = async (admin_id, name) => {
        const { date_start, date_end } = this.state;
        let start = moment(date_start).format("YYYY-MM-DD");
        let end = moment(date_end).format("YYYY-MM-DD");
        this.setState({ loading: true, search_check: false });
        // this.setState({ data_header: [] })
        try {
            let res = await POST(GETSALEAGENTBYSALEID, { date_start: start, date_end: end, admin_id });
            if (res.success) {
                // console.log(res.result);
                this.updateData(admin_id, res.result.agent_fee, name)
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    };

    getSaleManagerAgentById = async (admin_id, name) => {
        const { date_start, date_end } = this.state;
        let start = moment(date_start).format("YYYY-MM-DD");
        let end = moment(date_end).format("YYYY-MM-DD");
        this.setState({ loading: true, search_check: false });
        // this.setState({ data_header: [] })
        try {
            let res = await POST(GETSALEMANAGERTEAMAGENT, { date_start: start, date_end: end, admin_id });
            if (res.success) {
                // console.log(res.result);
                this.updateData(admin_id, res.result.agent_fee, name)
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    };

    getUserSaleAllAgent = async () => {
        const { date_start, date_end, search_text } = this.state;
        let start = moment(date_start).format("YYYY-MM-DD");
        let end = moment(date_end).format("YYYY-MM-DD");
        this.setState({ loading: true });
        try {
            let res = await POST(GETSALEAGENTALLSALE, { date_start: start, date_end: end, text_search: search_text });
            if (res.success) {
                const { data_tbl } = res.result
                this.setState({ data_tbl })
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    };

    getAgentSaleManagerAll = async () => {
        const { date_start, date_end, search_text } = this.state;
        let start = moment(date_start).format("YYYY-MM-DD");
        let end = moment(date_end).format("YYYY-MM-DD");
        this.setState({ loading: true });
        try {
            let res = await POST(GETSALEMANAGERAGENTALL, { date_start: start, date_end: end, text_search: search_text });
            if (res.success) {
                const { data_sale_manager_tbl } = res.result
                this.setState({ data_sale_manager_tbl })
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    };

    handleSearch = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    submitSearch = async () => {
        this.setState({ showAll: false, showAllSaleManager: false, search_check: true })
        this.getUserSale();
        this.getSaleLotBySearch();
    };

    handleDateStartChange = async (date) => {
        this.setState({ date_start: date });
    };

    handleDateEndChange = async (date) => {
        this.setState({ date_end: date });
    };

    handleShowAll = (name) => {
        // const { showAll } = this.state;
        this.setState({ [name]: !this.state[name] })
    };

    updateData = (adminId, agent_fee, name) => {

        const updatedData = this.state[name].map(item => (item.admin_id === adminId ? { ...item, agent_fee } : item));

        // console.log('updatedData', updatedData);

        this.setState({ [name]: updatedData });
    };

    handleChangePage = (value, name) => {
        this.setState({
            [name]: value,
            showAll: false,
            showAllSaleManager: false
        });
    };

    render() {
        const {
            loading,
            language,
            data_tbl,
            data_sale_manager_tbl,
            data_header,
            header,
            data_lot_all,
            data_lot_search,
            showAll,
            showAllSaleManager,
            search_check,
            data_sale_manager_team_tbl,
            pageSale,
            pageSaleManager,
            pageSaleManagerTeam,
        } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <Loading open={loading} />
                <ManagerCard
                    language={language}
                    state={this.state}
                    header={header}
                    showAll={showAll}
                    showAllSaleManager={showAllSaleManager}
                    data_tbl={data_tbl}
                    data_header={data_header}
                    search_check={search_check}
                    data_lot_all={data_lot_all}
                    data_lot_search={data_lot_search}
                    data_sale_manager_tbl={data_sale_manager_tbl}
                    data_sale_manager_team_tbl={data_sale_manager_team_tbl}
                    getUserSale={this.getUserSale}
                    handleSearch={this.handleSearch}
                    submitSearch={this.submitSearch}
                    handleShowAll={this.handleShowAll}
                    getSaleAgentBySale={this.getSaleAgentBySale}
                    getUserSaleAllAgent={this.getUserSaleAllAgent}
                    handleDateStartChange={this.handleDateStartChange}
                    handleDateEndChange={this.handleDateEndChange}
                    getSaleManagerAgentById={this.getSaleManagerAgentById}
                    handleChangePage={this.handleChangePage}
                    getAgentSaleManagerAll={this.getAgentSaleManagerAll}
                    pageSale={pageSale}
                    pageSaleManager={pageSaleManager}
                    pageSaleManagerTeam={pageSaleManagerTeam}
                />
            </Container>
        );
    }
}

export default withRouter(Manager);
