import React, { useEffect } from "react";
import { CardMedia, Grid, Button, Typography, Stack } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/styles";
import fornt from "../../../assets/images/drive-front.png";
import fornt_id from "../../../assets/images/image-idcard.png";
import bookbank from "../../../assets/logo/bookbank.png";
import back from "../../../assets/images/drive-back.png";
import active from "../../../assets/logo/active.png";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CancelIcon from "@material-ui/icons/Cancel";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { ip_image } from "service";
import { GridTextField } from "components";
import { textFiled } from "functions/Static";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

// const Status = (props) => {
//      const { data, language } = props;
//      return (
//           <>
//                {data.verify_doc === 1 ? (
//                     <CardMedia sx={{ objectFit: "contain", height: 26 }} component="img" alt="imageIdcard" image={active} />
//                ) : data.verify_doc === 2 ? (
//                     <Grid container justifyContent="center">
//                          <HourglassEmptyIcon color="primary" />
//                          <Typography>{language === "th" ? "ยังไม่ยืนยันตัวตน" : "Unverify"}</Typography>
//                     </Grid>
//                ) : data.verify_doc === 3 ? (
//                     <Grid container justifyContent="center">
//                          <CancelIcon style={{ color: "red" }} />
//                          <Typography>{language === "th" ? "ยืนยันตัวตนไม่สำเร็จ" : "Rejected"}</Typography>
//                     </Grid>
//                ) : data.verify_doc === 4 ? (
//                     <Grid container justifyContent="center">
//                          <HourglassFullIcon color="primary" />
//                          <Typography>{language === "th" ? "รอการยืนยัน" : "Waiting Confirm"}</Typography>
//                     </Grid>
//                ) : null}
//           </>
//      );
// };

const UploadDocBtn = (props) => {
     return (
          <Stack alignItems="center">
               <label htmlFor={props.name} style={{ width: 200 }}>
                    <Input
                         // accept="image/*"
                         accept="image/png, image/jpeg"
                         id={props.name}
                         type="file"
                         onChange={(e) => props.uploadFile(e, props.name, props.namefile)}
                    />
                    <Button variant="contained" style={{ backgroundColor: "#4589C6" }} component="span" className="btn-style" sx={{ mt: 2, maxWidth: 200 }}>
                         {props.language === "th" ? "เลือกไฟล์" : "Choose File"}
                    </Button>
               </label>
          </Stack>
     );
};

const ProfileBank = (props) => {
     const {
          onClickConfirm,
          data,
          state,
          language,
          submitEditBank,
          submitSaveBank,
          disabled_bank,
          onChangeText,
          menuItem,
          onClickUpLoadFrontDoc,
          onClickUpLoadBackDoc,
          onClickUpLoadBankDoc,
          role,
          onChangeSelect,
          image_bank,
          image_doc1,
          image_doc2,
          branch_name,
          bank_check,
     } = props;
     const classes = useStyles();

     let doc_image = data.document_path != null ? JSON.parse(data.document_path) : null;
     // let doc_image_type = doc_image[0].substring(doc_image[0].indexOf(".") + 1).toLowerCase();
     const [doc_image_type, setDocImageType] = React.useState('')

     const [stateDoc, setStateDoc] = React.useState({
          fornt_upload: null,
          back_upload: null,
          bookbank_upload: null,
          file_fornt_upload: null,
          file_back_upload: null,
          file_bookbank_upload: null,
     });

     const [fullImg, setFullImg] = React.useState(true)

     const uploadFile = (e, name, namefile) => {
          if (e.target.files && e.target.files[0]) {
               let reader = new FileReader();
               reader.onload = (e) => {
                    setStateDoc({ ...stateDoc, [name]: e.target.result });
               };
               reader.readAsDataURL(e.target.files[0]);
               if (namefile === "file_fornt_upload") {
                    state.file_fornt_upload = e.target.files[0];
                    onClickUpLoadFrontDoc(e.target.files[0]);
               } else if (namefile === "file_back_upload") {
                    state.file_back_upload = e.target.files[0];
                    onClickUpLoadBackDoc(e.target.files[0]);
               } else if (namefile === "file_bookbank_upload") {
                    state.file_bookbank_upload = e.target.files[0];
                    onClickUpLoadBankDoc(e.target.files[0]);
               }
               setStateDoc({ ...stateDoc });
          }
     };

     const fullScreenImg = () => {
          setFullImg(!fullImg);
     }

     const openInNewTab = (url) => {
          const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
          if (newWindow) newWindow.opener = null
     }

     useEffect(() => {
          if (doc_image) {
               let objDoc = doc_image[0]?.substring(doc_image[0]?.indexOf(".") + 1).toLowerCase();
               setDocImageType(objDoc)
          }
     }, [data.document_path]);

     return (
          <Grid item container direction="row" xs={12} spacing={2}>
               {(role !== "sale" && role !== "sale_manager") && (
                    <Grid item container justifyContent="flex-end">
                         {disabled_bank && (
                              <Button
                                   variant="contained"
                                   style={{ backgroundColor: "#4589C6" }}
                                   sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40, ml: 2 }}
                                   onClick={() => submitEditBank()}
                              >
                                   {language === "th" ? "แก้ไข" : "Edit"}
                              </Button>
                         )}
                    </Grid>
               )}
               <Grid item xs={12}>
                    <Typography fontWeight={600}>Book Bank</Typography>
               </Grid>
               <Grid item xs={12}>
                    {/* <Status language={props.language} data={data} /> */}
                    <Grid sx={{ background: "#f8f8f8", p: 3, width: "auto", height: "auto", mt: 1 }}>
                         {!stateDoc.bookbank_upload && (
                              <>
                                   {(doc_image && doc_image_type === "pdf") ?
                                        <Grid py={5} display="flex" justifyContent="center" alignItems="center">
                                             <Button onClick={() => openInNewTab(image_doc1)}>Open File</Button>
                                        </Grid>
                                        :
                                        <>
                                             {fullImg &&
                                                  <CardMedia onClick={fullScreenImg} className="zoom-out" component="img" alt="imageBank" image={data.bank_path ? image_bank : bookbank} />
                                             }
                                             {!fullImg &&
                                                  <>
                                                       <Grid display="flex" justifyContent="flex-end">
                                                            <Button onClick={fullScreenImg}>X</Button>
                                                       </Grid>
                                                       <Grid className="full-img-modal-modal-content animate-zoom">
                                                            <CardMedia component="img" alt="imageBank" image={data.bank_path ? image_bank : bookbank} />
                                                       </Grid>
                                                  </>
                                             }
                                        </>
                                   }
                              </>
                         )}
                         {stateDoc.bookbank_upload && (
                              <>
                                   <Grid container justifyContent="center" mt={2}>
                                        <CheckCircleOutlineIcon style={{ color: "#82C729" }} />
                                        <Typography>{language === "th" ? "อัพโหลดใหม่" : "New Upload"}</Typography>
                                   </Grid>
                                   <CardMedia className={classes.imgStyle} sx={{ marginTop: 2 }} component="img" alt="imageIdcard" image={stateDoc.bookbank_upload} />
                              </>
                         )}
                    </Grid>
                    <CardMedia sx={{ width: "400px", height: "auto" }} image="/static/images/cards/paella.jpg" title="Paella dish" />
                    {!disabled_bank && (
                         <UploadDocBtn
                              name="bookbank_upload"
                              namefile="file_bookbank_upload"
                              base64={stateDoc.bookbank_upload}
                              uploadFile={uploadFile}
                              img={doc_image ? ip_image + doc_image[0] : fornt}
                              language={language}
                         />
                    )}
                    <Grid item container justifyContent="center" xs={12} marginTop={2}>
                         <Grid item container spacing={1} xs={12} md={6}>
                              <GridTextField data={state.upload_bookbank_select} state={state} handleChange={onChangeSelect} menuItem={menuItem} />
                              {bank_check &&
                                   <GridTextField data={state.upload_bookbank_other} state={state} handleChange={onChangeText} />
                              }
                              <GridTextField data={state.upload_bookbank} state={state} handleChange={onChangeText} menuItem={menuItem} />
                         </Grid>
                    </Grid>
               </Grid>
               {data.verify_bank === 4 && (
                    <Grid item container justifyContent="center" xs={12}>
                         <Button className="btn-style" variant="contained" color="success" sx={{ maxWidth: 160, marginTop: 10, marginX: 2 }} onClick={() => onClickConfirm(1)}>
                              Confirm
                         </Button>
                         <Button className="btn-style" variant="contained" color="error" sx={{ maxWidth: 160, marginTop: 10, marginX: 2 }} onClick={() => onClickConfirm(3)}>
                              Rejected
                         </Button>
                    </Grid>
               )}
               {!disabled_bank && (
                    <Grid item container justifyContent="center">
                         <Button
                              variant="contained"
                              style={{ backgroundColor: "#4589C6" }}
                              sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40, ml: 2 }}
                              onClick={() => submitSaveBank()}
                         >
                              {language === "th" ? "บันทึก" : "Save"}
                         </Button>
                    </Grid>
               )}
          </Grid>
     );
};
const Input = styled("input")({
     display: "none",
});
const useStyles = makeStyles(() => ({
     imgStyle: {
          objectFit: "contain",
          height: 209,
          // [theme.breakpoints.up("md")]: {
          //      height: 140,
          // },
     },
}));
export default ProfileBank;
