import { Container } from "@material-ui/core";
import { headerTable } from "functions/Static";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { GETUSERBYSALE, POST } from "service";
import swal from "sweetalert2";
import ManagerMemberCard from "./components/ManagerMemberCard";
import Loading from "components/Loading/Loading";

export class ManagerMember extends Component {
    constructor(props) {
        super(props);

        this.state = {
            language: localStorage.getItem("language"),
            loading: false,
            data: [],
            page: 1,
            // profile_name: this.props.location.state.username
            profile: JSON.parse(this.props.match.params.detail),
        };
    }
    componentDidMount = () => {
        this.getUserBySale();
    };

    getUserBySale = async () => {
        const { profile } = this.state;
        this.setState({ loading: true });
        try {
            let res = await POST(GETUSERBYSALE, { admin_id: profile.id });
            if (res.success) {
                this.setState({ data: res.result })
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    };

    handleChangePage = (e, value) => {
        this.setState({ page: value });
    };

    render() {
        const { loading, language, data, page, profile } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <Loading open={loading} />
                <ManagerMemberCard
                    language={language}
                    data={data}
                    type={profile.type}
                    head={profile.type === 'saleManagerTeam' ? headerTable.saleManagerMemberList : profile.type === 'saleManagerSaleTeam' ? headerTable.managerSaleMember : headerTable.managerMemberList}
                    page={page}
                    handleChangePage={this.handleChangePage}
                    profile_name={profile.username}
                />
            </Container>
        );
    }
}

export default withRouter(ManagerMember);
