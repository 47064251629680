import { Button, Grid } from "@material-ui/core";
import { GridTextField } from "components";
import HeaderTypography from "components/HeaderTypography/HeaderTypography";
import { textFiled } from "functions/Static";
import Sidebar from "pages/Setting/Components/Sidebar";

const AddAdminCard = (props) => {
     const { state, handleChange, language, onClickAddAdmin } = props;
     return (
          <Grid container spacing={2} width={"100%"}>
               <Grid item xs={3}>
                    <Sidebar title={"Add Admin"} pageName={"admin"} />
               </Grid>
               <Grid container spacing={2} item xs={9} alignContent={"start"}>
                    <Grid item xs={12}>
                         <HeaderTypography title={language === "th" ? "เพิ่มผู้ดูแล" : "Add Admin"} />
                    </Grid>
                    <GridTextField data={textFiled.add_admin} state={state} handleChange={handleChange} menuItem={menuItem} />
                    <Grid item xs={12} container justifyContent={"center"} mt={5}>
                         <Button
                              variant="contained"
                              style={{ backgroundColor: "#4589C6" }}
                              //  className="btn-style"
                              sx={{ maxWidth: 150, fontSize: 16, width: "100%", borderRadius: 40, height: 50 }}
                              onClick={onClickAddAdmin}
                         >
                              {language === "th" ? "เพิ่มผู้ดูแล" : "Add Admin"}
                         </Button>
                    </Grid>
               </Grid>
          </Grid>
     );
};

const menuItem = [
     { label: "Select Role", value: "null", disabled: true },
     { label: "Admin", value: "admin" },
     { label: "Sale", value: "sale" },
     { label: "Sale Manager", value: "sale_manager" },
     { label: "Normal", value: "normal" },
     { label: "Customer officer", value: "customer_officer" },
];

export default AddAdminCard;
