import { Autocomplete, Button, Grid, Stack, TextField, Typography } from "@material-ui/core";
import { tofieds } from "functions/Functions";
import { headerTable } from "functions/Static";
import DatePicker from "react-date-picker";
import TableReport from "./TableReport";
import { BeatLoader } from "react-spinners";

const override = {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
};

const Report = (props) => {
    const {
        data,
        language,
        page,
        handleChangePage,
        handleChange,
        state,
        onClickSearch,
        handleDateStartChange,
        handleDateEndChange,
        mt5_list,
        data_lot,
        accountType_list,
        count_page,
    } = props;

    const count = Math.ceil(count_page > 0 && count_page / 50);
    const item = data_lot;

    return (
        <Grid container spacing={5} justifyContent="center" alignItems="center" mt={5}>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                    {language === "th" ? "รายงาน MT5" : "Report MT5"}
                </Typography>
                <Grid item container direction={"row"} my={3} >
                    {head_.map((field, index) =>
                        <Grid key={index} item xs={12} sm={6} md={3}>
                            <Stack direction="row" spacing={1} width="100%" display="flex" justifyContent="flex-strat" alignItems="center">
                                <Typography>
                                    {field.label}
                                </Typography>
                                <Typography sx={{ fontSize: 20, color: "#0d47a1" }}>
                                    {field.suffix}
                                </Typography>
                                {item[field.name] !== undefined ?
                                    <Typography sx={{ fontSize: 20, color: "#0d47a1" }}>
                                        {tofieds(item[field.name]) || "0.00"}
                                    </Typography>
                                    :
                                    <BeatLoader
                                        color="#0d47a1"
                                        loading={item[field.name] !== undefined ? false : true}
                                        cssOverride={override}
                                        size={5}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                }
                            </Stack>
                        </Grid>
                    )}
                    {/* <Grid item xs={12} sm={3} md={2}>
                        <Stack spacing={2} direction="row" marginBottom={2} mt={2}>
                            <Typography>Lot รวม : </Typography>
                            <Typography sx={{ color: "#184B8E" }}>${tofieds(data_lot.sumLot) || "0.00"}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                        <Stack spacing={2} direction="row" marginBottom={2} mt={2}>
                            <Typography>Profits: </Typography>
                            <Typography sx={{ color: "#184B8E" }}>${tofieds(data_lot.profit) || "0.00"}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                        <Stack spacing={2} direction="row" marginBottom={2} mt={2}>
                            <Typography>Lost: </Typography>
                            <Typography sx={{ color: "#184B8E" }}>${tofieds(data_lot.lost) || "0.00"}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                        <Stack spacing={2} direction="row" marginBottom={2} mt={2}>
                            <Typography>Net Profit: </Typography>
                            <Typography sx={{ color: "#184B8E" }}>${tofieds(data_lot.net_profit) || "0.00"}</Typography>
                        </Stack>
                    </Grid> */}
                </Grid>
                <Stack direction="row" alignItems="center" mt={2}>
                    <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2, display: { xs: "none", md: "block" } }}>
                        {language === "th" ? "ค้นหา" : "Search"}:
                    </Typography>
                    <Grid container xs={12} md={12} lg={12} sx={{ mr: 2 }} spacing={1} alignItems="center">
                        <Grid item xs={12} sm={12} md={2.5}>
                            <Autocomplete
                                id="search_account"
                                disableClearable={true}
                                disablePortal
                                getOptionLabel={(option) =>
                                    typeof option === 'string' ? option : `${option.label}`
                                }
                                options={accountType_list}
                                value={state.accountType_select}
                                onChange={(event, value) => handleChange('search_account', value)}
                                renderInput={(params) => <TextField {...params} label="Select account type" />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2.5}>
                            <Autocomplete
                                id="search_mt5"
                                disableClearable={true}
                                disablePortal
                                getOptionLabel={(option) =>
                                    typeof option === 'string' ? option : `${option.label}`
                                }
                                options={mt5_list}
                                value={state.mt5_select}
                                onChange={(event, value) => handleChange('search_mt5', value)}
                                renderInput={(params) => <TextField {...params} label="Select MT5 account" />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2.5}>
                            <DatePicker className={"date-picker"} onChange={handleDateStartChange} value={state.date_start} format="dd/MM/yyyy" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2.5}>
                            <DatePicker className={"date-picker"} onChange={handleDateEndChange} value={state.date_end} format="dd/MM/yyyy" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2}>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "#4589C6" }}
                                sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                                onClick={onClickSearch}
                            >
                                {language === "th" ? "ค้นหา" : "Search"}
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <TableReport
                    head={headerTable.report_mt5}
                    // body={dataList}
                    body={data}
                    count={count}
                    page={page}
                    handleChangePage={handleChangePage}
                    language={language}
                />
            </Grid>
        </Grid>
    );
};

const head_ = [
    { label: "Lot รวม :", suffix: "$", name: "sumLot" },
    { label: "Profits :", suffix: "$", name: "profit" },
    { label: "Lost :", suffix: "$", name: "lost" },
    { label: "Net Profit :", suffix: "$", name: "net_profit" },

]

export default Report;
