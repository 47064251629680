import { Box, Button, Grid, IconButton, Link, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import { makeStyles } from "@material-ui/styles";
import { tofieds } from "functions/Functions";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";

const override = {
     display: "flex",
     justifyContent: 'center',
     alignItems: 'center',
};

const GridTableSaleList = (props) => {
     const classes = useStyles();
     const history = useHistory();

     const [show, setShow] = useState({});

     const handleShow = (id) => {
          setShow((prevState) => ({
               ...prevState,
               [id]: !prevState[id], // สลับสถานะการแสดงข้อมูลของแถวที่คลิก
          }));
     };

     // const onClicView = (id, name, lastname) => {
     //      // history.push(`/memberdetail/${id}`);
     //      history.push({
     //           pathname: `/accountmanager/memberdetail/${id}`,
     //           state: { username: `${name} ${lastname}` },
     //      });
     // };

     const onClicViewMember = (id, name, lastname) => {
          return () => {
               window.open(
                    `/accountmanager/memberdetail/${JSON.stringify({
                         id: id,
                         username: `${name} ${lastname}`,
                    })}`,
                    "_blank"
               );
          };
     };

     // const onClicViewTran = (id, name, lastname) => {
     //      history.push({
     //           pathname: `/accountmanager/transactiondetail/${id}`,
     //           state: {
     //                username: `${name} ${lastname}`,
     //                date_start: props.date_start,
     //                date_end: props.date_end,
     //           },
     //      });
     // };

     const onClicViewTran = (id, name, lastname) => {
          return () => {
               window.open(
                    `/accountmanager/transactiondetail/${JSON.stringify({
                         id: id,
                         username: `${name} ${lastname}`,
                         date_start: props.date_start,
                         date_end: props.date_end,
                    })}`,
                    "_blank"
               );
          };
     };

     const handleRoute = (detail) => {
          return () => {
               window.open(
                    `/accountmanager/lotdetail/${JSON.stringify({
                         id: detail.admin_id,
                         name: `${detail.name} ${detail.lastname}`,
                         date_start: props.date_start,
                         date_end: props.date_end,
                    })}`,
                    "_blank"
               );
          };
     };

     useEffect(() => {
          if (props.showAll) {
               const allShow = props.body.reduce((acc, el) => {
                    acc[el.admin_id] = true;
                    return acc;
               }, {});
               setShow(allShow);
          } else {
               setShow({});
          }
     }, [props.showAll]);

     useEffect(() => {
          if (props.search_check) {
               setShow({});
          }
     }, [props.search_check]);

     //-------------------------------------------------//
     return (
          <Grid className="table-btn">
               <TableContainer>
                    <Table className={classes.table}>
                         <TableHead>
                              <TableRow>
                                   {props.head.map((el, i) => {
                                        return (
                                             <TableCell key={i} align={el.align || "left"}>
                                                  {el.label === "Agent Fee" ?
                                                       <Stack direction="row" width="100%" display="flex" alignItems="center" justifyContent="center" spacing={3}>
                                                            <Typography noWrap>{el.label}</Typography>
                                                            <Grid>
                                                                 {props.showAll ?
                                                                      <IconButton
                                                                           onClick={() => {
                                                                                props.handleShowAll();
                                                                                props.getUserSale();
                                                                           }}
                                                                      >
                                                                           <CancelPresentationIcon fontSize="large" />
                                                                      </IconButton>
                                                                      :
                                                                      <IconButton
                                                                           onClick={() => {
                                                                                props.handleShowAll();
                                                                                props.getUserSaleAllAgent();
                                                                           }}
                                                                      >
                                                                           <SettingsOverscanIcon fontSize="large" />
                                                                      </IconButton>
                                                                 }
                                                            </Grid>
                                                       </Stack>
                                                       :
                                                       <Typography noWrap>{el.label}</Typography>
                                                  }
                                                  {/* <Typography noWrap>{el.label}</Typography> */}
                                             </TableCell>
                                        );
                                   })}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {props.body.map((el, i) => {
                                   return (
                                        <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                             <TableCell >
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.name} {el.lastname}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="right">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {Number(el.sumDeposit).toFixed(4) || "0.0000"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="right">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {Number(el.sumWithdraw).toFixed(4) || "0.0000"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="right">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {Number(el.sumNetDeposit).toFixed(4) || "0.0000"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="center">
                                                  <Stack width="100%" spacing={1} display="flex" justifyContent="center" alignItems="center">
                                                       <Grid>
                                                            {show[el.admin_id] ?
                                                                 <Button onClick={() => {
                                                                      handleShow(el.admin_id);
                                                                 }}>
                                                                      {props.language === "th" ? "ซ่อนข้อมูล" : "Hide"}
                                                                 </Button>
                                                                 :
                                                                 <Button onClick={() => {
                                                                      props.getSaleAgentBySale(el.admin_id);
                                                                      handleShow(el.admin_id);
                                                                 }}>
                                                                      {props.language === "th" ? "แสดงข้อมูล" : "Show"}
                                                                 </Button>
                                                            }
                                                       </Grid>
                                                       {show[el.admin_id] &&
                                                            <Grid>
                                                                 {el.agent_fee !== undefined ?
                                                                      <Typography noWrap sx={{ fontSize: 16, color: "#0d47a1" }}>
                                                                           {tofieds(el.agent_fee) || "0.00"}
                                                                      </Typography>
                                                                      :
                                                                      <Box display="flex" justifyContent="center" alignItems="center">
                                                                           <BeatLoader
                                                                                color="#0d47a1"
                                                                                loading={el.agent_fee !== undefined ? false : true}
                                                                                cssOverride={override}
                                                                                size={5}
                                                                                aria-label="Loading Spinner"
                                                                                data-testid="loader"
                                                                           />
                                                                      </Box>
                                                                 }
                                                            </Grid>
                                                       }
                                                  </Stack>
                                             </TableCell>
                                             <TableCell align="center">
                                                  <Link
                                                       component="button"
                                                       sx={{ fontSize: 16, color: "#184B8E" }}
                                                       // onClick={() => {
                                                       //      onClicViewTran(el.admin_id, el.name, el.lastname);
                                                       // }}
                                                       onClick={onClicViewTran(el.admin_id, el.name, el.lastname)}
                                                  >
                                                       View
                                                  </Link>
                                             </TableCell>
                                             <TableCell align="center">
                                                  <Link
                                                       component="button"
                                                       sx={{ fontSize: 16, color: "#184B8E" }}
                                                       // onClick={() => {
                                                       //      onClicView(el.admin_id, el.name, el.lastname);
                                                       // }}
                                                       onClick={onClicViewMember(el.admin_id, el.name, el.lastname)}
                                                  >
                                                       View
                                                  </Link>
                                             </TableCell>
                                             <TableCell align="center">
                                                  <Link
                                                       component="button"
                                                       sx={{ fontSize: 16, color: "#184B8E" }}
                                                       onClick={handleRoute(el)}
                                                  >
                                                       View
                                                  </Link>
                                             </TableCell>
                                        </TableRow>
                                   );
                              })}
                         </TableBody>
                    </Table>
               </TableContainer>
               <Grid container justifyContent="center" mt={5}>
                    {props.body.length === 0 && (
                         <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                              {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                         </Typography>
                    )}
                    {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
               </Grid>
          </Grid >
     );
};

const useStyles = makeStyles({
     table: {
          minWidth: 320,
     },
     fontActive: {
          fontSize: 15,
          color: "#fff",
          padding: 8,
          borderRadius: 10,
     },
});

export default GridTableSaleList;
