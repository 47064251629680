import { Container } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import moment from "moment";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { GET, GETAGENTBYSALEMANAGER, GETDATAALLSALETEAM, GETLOTALLSALETEAM, GETSALEAGENTBYSALEID, GETSALEMANAGERLOTBYSEARCH, GETSALETEAMNOLOTBYSEARCH, POST } from "service";
import swal from "sweetalert2";
import SaleCard from "./components/SaleCard";

export class SaleManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            language: localStorage.getItem("language"),
            date_start: new Date(),
            date_end: new Date(),
            header: [],
            data_tbl: [],
            data_header: [],
            search_text: '',
            data_lot_all: [],
            data_lot_search: [],
            showAll: false,
            search_check: false,
            loading: false,
        };
    }
    componentDidMount = () => {
        this.getUserSale();
        this.getUserSaleHead();
        this.getSaleLotAll();
        this.getSaleLotBySearch();
    };

    getUserSaleHead = async () => {
        // this.setState({ loading: true });
        try {
            let res = await GET(GETDATAALLSALETEAM);
            if (res.success) {
                this.setState({ header: res.result })
                // this.setState({ loading: false });
            } else {
                // this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            // this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    };

    getUserSale = async () => {
        const { date_start, date_end, search_text } = this.state;
        let start = moment(date_start).format("YYYY-MM-DD");
        let end = moment(date_end).format("YYYY-MM-DD");
        // this.setState({ loading: true });
        try {
            let res = await POST(GETSALETEAMNOLOTBYSEARCH, { date_start: start, date_end: end, text_search: search_text });
            if (res.success) {
                const { data_tbl, data_header } = res.result
                this.setState({ data_tbl, data_header })
                // this.setState({ loading: false });
            } else {
                // this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            // this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    };

    getSaleLotAll = async () => {
        const { language } = this.state;
        try {
            this.setState({ loading: true });
            let res = await POST(GETLOTALLSALETEAM, { lang: language });
            if (res.success) {
                // console.log(res.result);
                this.setState({ data_lot_all: res.result })
                this.setState({ loading: false });
            }
        } catch (error) {
            this.setState({ loading: false });
            console.log("error", error);
        }
    };

    getSaleLotBySearch = async () => {
        const { language, date_start, date_end, search_text } = this.state;
        let start = moment(date_start).format("YYYY-MM-DD");
        let end = moment(date_end).format("YYYY-MM-DD");
        try {
            this.setState({ loading: true });
            let res = await POST(GETSALEMANAGERLOTBYSEARCH, { lang: language, date_start: start, date_end: end, text_search: search_text });
            if (res.success) {
                this.setState({ data_lot_search: res.result })
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
        }
    };

    getSaleAgentBySale = async (admin_id) => {
        const { date_start, date_end } = this.state;
        let start = moment(date_start).format("YYYY-MM-DD");
        let end = moment(date_end).format("YYYY-MM-DD");
        this.setState({ loading: true, search_check: false });
        // this.setState({ data_header: [] })
        try {
            let res = await POST(GETSALEAGENTBYSALEID, { date_start: start, date_end: end, admin_id });
            if (res.success) {
                // console.log(res.result);
                this.updateData(admin_id, res.result.agent_fee)
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    };

    getUserSaleAllAgent = async () => {
        const { date_start, date_end, search_text } = this.state;
        let start = moment(date_start).format("YYYY-MM-DD");
        let end = moment(date_end).format("YYYY-MM-DD");
        this.setState({ loading: true });
        try {
            let res = await POST(GETAGENTBYSALEMANAGER, { date_start: start, date_end: end, text_search: search_text });
            if (res.success) {
                const { data_tbl, data_header } = res.result
                this.setState({ data_tbl, data_header })
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    };

    handleSearch = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    submitSearch = async () => {
        this.setState({ showAll: false, search_check: true })
        this.getUserSale();
        this.getSaleLotBySearch();
    };

    handleDateStartChange = async (date) => {
        this.setState({ date_start: date });
    };

    handleDateEndChange = async (date) => {
        this.setState({ date_end: date });
    };

    handleShowAll = () => {
        const { showAll } = this.state;
        this.setState({ showAll: !showAll })
    };

    updateData = (adminId, agent_fee) => {
        const { data_tbl } = this.state;

        // console.log('data_tbl', data_tbl);

        const updatedData = data_tbl.map(item => (item.admin_id === adminId ? { ...item, agent_fee } : item));

        // console.log('updatedData', updatedData);

        this.setState({ data_tbl: updatedData });
    };


    render() {
        const { loading, language, data_tbl, data_header, header, data_lot_all, data_lot_search, showAll, search_check } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <Loading open={loading} />
                <SaleCard
                    language={language}
                    handleSearch={this.handleSearch}
                    submitSearch={this.submitSearch}
                    state={this.state}
                    handleDateStartChange={this.handleDateStartChange}
                    handleDateEndChange={this.handleDateEndChange}
                    data_tbl={data_tbl}
                    data_header={data_header}
                    header={header}
                    data_lot_all={data_lot_all}
                    data_lot_search={data_lot_search}
                    handleShowAll={this.handleShowAll}
                    showAll={showAll}
                    getSaleAgentBySale={this.getSaleAgentBySale}
                    getUserSaleAllAgent={this.getUserSaleAllAgent}
                    getUserSale={this.getUserSale}
                    search_check={search_check}
                />
            </Container>
        );
    }
}

export default withRouter(SaleManager);
