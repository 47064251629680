import { Card, CardContent, Grid, Button, Typography, Stack, Tooltip, Chip } from "@material-ui/core";
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';

const ListSaleTeam = (props) => {

    return props.data.map((el, i) => {
        return (
            <Card sx={{ maxWidth: "100%", marginBottom: 2, padding: 1 }}>
                <Grid container direction="row">
                    <Grid item container xs={12} display="flex" alignItems="center">
                        <Grid item xs={12} sm={4}>
                            <CardContent>
                                <Grid container direction="row" xs={12}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" color="text.primary">
                                            {el.name} {el.lastname}
                                        </Typography>
                                        {el.user_team?.map((item, index) => {
                                            return (
                                                el.expand === 0 ?
                                                    (index < 2 &&
                                                        <Stack my={1}>
                                                            <Typography>
                                                                • {`${item.sale_name} ${item.sale_lastname}`}
                                                            </Typography>
                                                            {/* <Typography>
                                                                &nbsp;({item.email})
                                                            </Typography> */}
                                                        </Stack>
                                                    )
                                                    :
                                                    (
                                                        <Stack my={1}>
                                                            <Typography>
                                                                • {`${item.sale_name} ${item.sale_lastname}`}
                                                            </Typography>
                                                            {/* <Typography>
                                                                &nbsp;({item.email})
                                                            </Typography> */}
                                                        </Stack>
                                                    )
                                            )
                                        })}
                                        {el.user_team?.length > 2 &&
                                            <Chip
                                                deleteIcon={<MoreHorizRoundedIcon />}
                                                onClick={() => props.updateDataList(el.admin_id, el.expand)}
                                                onDelete={() => props.updateDataList(el.admin_id, el.expand)}
                                                label={el.expand === 0 ? "more" : "less"}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Grid>
                        <Grid container item direction="row" justifyContent="flex-end" xs={12} sm={8}>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "#4589C6" }}
                                className="btn-style"
                                sx={{ maxWidth: 100, fontSize: 12, width: "100%", borderRadius: 40, height: 40, margin: 1 }}
                                onClick={() => props.onClickEdit(el.admin_id, el.name, el.lastname)}
                            >
                                Edit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        );
    });
};

export default ListSaleTeam;
