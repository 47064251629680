import { Button, Grid, Stack, Typography } from "@material-ui/core";
import { GridTextField } from "components";
import { headerTable, textFiled } from "functions/Static";
import React, { useState } from "react";
import TableManageUser from "./TableManageUser";
import ModalSelectSale from "./ModalSelectSale";
import TableManageUserNest from "./TableManageUserNest";

const ManageUserCard = (props) => {
     const {
          state,
          handleChangeText,
          submitSearch,
          language,
          data_table,
          page,
          handleChangePage,
          item_sale,
          onClickOpen,
          onClickClose,
          open,
          onClickAssingUser,
          role,
          onClickChangeToManager,
          onClickCancelUserSale
     } = props;
     const count = Math.ceil(data_table.length > 0 && data_table.length / 10);
     const setPage = page - 1;
     const dataList = data_table.slice(setPage * 10, page * 10);
     return (
          <Grid container columns={14} justifyContent="center" spacing={2}>
               <Grid item xs={14}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                         {language === "th" ? "จัดการลูกค้า" : "Manage User"}
                    </Typography>
                    <Stack direction="row" alignItems="center" mt={2}>
                         <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2 }}>Search:</Typography>
                         <Grid container sx={{ width: "40vw", mr: 2 }}>
                              <GridTextField
                                   data={textFiled.search_text_manage}
                                   state={state}
                                   handleChange={handleChangeText}
                                   onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                             submitSearch();
                                        }
                                   }}
                              />
                         </Grid>
                         <Button variant="contained" style={{ backgroundColor: "#4589C6" }} sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }} onClick={submitSearch}>
                              {language === "th" ? "ค้นหา" : "Search"}
                         </Button>
                    </Stack>
               </Grid>
               {/* <Grid item xs={12}>
                    <TableManageUser
                         head={role === "admin" || role === "normal" ? headerTable.managemember_admin : headerTable.managemember_user}
                         body={dataList}
                         count={count}
                         handleChangePage={handleChangePage}
                         language={language}
                         onClickOpen={onClickOpen}
                         onClickClose={onClickClose}
                         role={role}
                         onClickChangeToManager={onClickChangeToManager}
                    />
               </Grid> */}
               <Grid item xs={14}>
                    <TableManageUserNest
                         head={(role === "admin" || role === "normal" || role === "customer_officer") ? headerTable.managemember_admin : headerTable.managemember_user}
                         body={dataList}
                         count={count}
                         handleChangePage={handleChangePage}
                         language={language}
                         onClickOpen={onClickOpen}
                         onClickClose={onClickClose}
                         role={role}
                         onClickChangeToManager={onClickChangeToManager}
                         onClickCancelUserSale={onClickCancelUserSale}
                    />
               </Grid>
               <Grid item xs={14}>
                    <ModalSelectSale
                         open={open}
                         onClickClose={onClickClose}
                         state={state}
                         handleChangeText={handleChangeText}
                         item_sale={item_sale}
                         onClickAssingUser={onClickAssingUser}
                    />
               </Grid>
          </Grid>
     );
};

export default ManageUserCard;
