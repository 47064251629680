import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import GridTableMember from "./GridTableMember";

const override = {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
};

const ManagerMemberCard = (props) => {
    const {
        language,
        data,
        head,
        page,
        handleChangePage,
        profile_name,
        type,
    } = props;

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000", mt: 3 }}>{profile_name}</Typography>
            </Grid>
            <Grid item xs={12}>
                <GridTableMember
                    language={language}
                    data={data}
                    head={head}
                    page={page}
                    handleChangePage={handleChangePage}
                    profile_name={profile_name}
                    type={type}
                />
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    paper: {
        padding: 20,
    },
}));
export default ManagerMemberCard;
