import { Container } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { DELETESALETEAM, EDITSALETEAM, GET, GETSALE, GETSALEMANAGERBYID, POST } from "service";
import swal from "sweetalert2";
import SettingSaleTeamManage from "./Components/SettingSaleTeamManage";

class SettingTeamSale extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: false,
            profile: this.props.location.state.username,
            data_team: [],
            select_team: [],
            select_data_team: [],
            team_edit: [],
        };
    }

    componentDidMount() {
        this.getSale();
        this.getSaleManagerById();
    }

    getSale = async () => {
        try {
            this.setState({ loading: true });
            let res = await GET(GETSALE);
            if (res.success) {
                this.setState({
                    data_team: res.result,
                });
                this.setState({ loading: false });
            } else {
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }
    };

    getSaleManagerById = async () => {
        try {
            this.setState({ loading: true });
            let res = await POST(GETSALEMANAGERBYID, { sale_manager_id: this.props.match.params.id });
            if (res.success) {
                this.setState({
                    loading: false,
                    select_data_team: res.result,
                    select_team: res.result.map(({ admin_id }) => admin_id.toString()),
                    team_edit: res.result.map(({ admin_id }) => admin_id.toString())
                });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            this.setState({ loading: false });
            console.log(error);
        }
    };

    handleChangeText = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onClickEdit = () => {
        const { select_team, team_edit } = this.state;

        let deleted = team_edit.filter(item => !select_team.includes(item));
        let inserted = select_team.filter(item => !team_edit.includes(item));

        this.onClickUpdate(this.props.match.params.id, deleted, inserted)
    };

    onClickUpdate = (sale_manager_id, deleted, inserted) => {
        const { language } = this.state;
        swal.fire({
            title: `${language === "th" ? "แก้ไขเซลล์ทีม" : "Edit Sale Team"}`,
            text: `${language === "th" ? `คุณต้องการแก้ไขเซลล์ในทีม?` : `Do you want to edit sale in team?`}`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Submit",
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    this.setState({ loading: true });
                    let res = await POST(EDITSALETEAM, { sale_manager_id, deleted, inserted });
                    if (res.success) {
                        this.setState({ loading: false });
                        swal.fire({
                            title: "สำเร็จ", text: `${language === "th" ? `แก้ไขเซลล์ในทีมสำเร็จ` : `Edit sale in team success`}`, icon: "success",
                            showConfirmButton: true
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.assign("/setting_sale_manager");
                            }
                        })
                    } else {
                        this.setState({ loading: false });
                        swal.fire({
                            title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                            showConfirmButton: true
                        });
                    }
                } catch (error) {
                    this.setState({ loading: false });
                    swal.fire({
                        title: "ไม่สำเร็จ", text: error, icon: "warning",
                        showConfirmButton: true
                    });
                }
            }
        });
    };

    // onClickUpdate = (sale_manager_id, data) => {
    //     const { language } = this.state;
    //     swal.fire({
    //         title: `${language === "th" ? "เพิ่มเซลล์ทีม" : "Add Sale Team"}`,
    //         text: `${language === "th" ? `คุณต้องการเพิ่มเซลล์ในทีม?` : `Do you want to add sale in team?`}`,
    //         icon: "question",
    //         showCancelButton: true,
    //         confirmButtonText: "Submit",
    //         cancelButtonText: "Cancel",
    //     }).then(async (result) => {
    //         if (result.isConfirmed) {
    //             try {
    //                 this.setState({ loading: true });
    //                 let res = await POST(EDITSALETEAM, { sale_manager_id, data });
    //                 if (res.success) {
    //                     this.setState({ loading: false });
    //                     swal.fire({
    //                         title: "สำเร็จ", text: `${language === "th" ? `เพิ่มเซลล์ในทีมสำเร็จ` : `Add sale in team success`}`, icon: "success",
    //                         showConfirmButton: true
    //                     }).then((result) => {
    //                         if (result.isConfirmed) {
    //                             window.location.assign("/setting_sale_manager");
    //                         }
    //                     })
    //                 } else {
    //                     this.setState({ loading: false });
    //                     swal.fire({
    //                         title: "ไม่สำเร็จ", text: res.message, icon: "warning",
    //                         showConfirmButton: true
    //                     });
    //                 }
    //             } catch (error) {
    //                 this.setState({ loading: false });
    //                 swal.fire({
    //                     title: "ไม่สำเร็จ", text: error, icon: "warning",
    //                     showConfirmButton: true
    //                 });
    //             }
    //         }
    //     });
    // };

    onClickDelete = (sale_manager_id, data) => {
        const { language } = this.state;
        swal.fire({
            title: `${language === "th" ? "ลบเซลล์ทีม" : "Delete Sale Team"}`,
            text: `${language === "th" ? `คุณต้องการลบเซลล์ในทีม?` : `Do you want to delete sale in team?`}`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Submit",
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    this.setState({ loading: true });
                    let res = await POST(DELETESALETEAM, { sale_manager_id, data });
                    if (res.success) {
                        this.setState({ loading: false });
                        swal.fire({
                            title: "สำเร็จ", text: `${language === "th" ? `ลบเซลล์ในทีมสำเร็จ` : `Delete sale in team success`}`, icon: "success",
                            showConfirmButton: true
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.assign("/setting_sale_manager");
                            }
                        })
                    } else {
                        this.setState({ loading: false });
                        swal.fire({
                            title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                            showConfirmButton: true
                        });
                    }
                } catch (error) {
                    this.setState({ loading: false });
                    swal.fire({
                        title: "ไม่สำเร็จ", text: error, icon: "warning",
                        showConfirmButton: true
                    });
                }
            }
        });
    };

    onChangeTeam = (valueAuto) => {
        let team = [];
        // console.log(valueAuto);

        let sale_id_team = valueAuto.map(({ admin_id }) => admin_id.toString())
        // console.log(sale_id_team);

        team.push(...valueAuto);
        this.setState({ select_team: sale_id_team, select_data_team: team });
    };

    handleChangeCheck = (e) => {
        this.setState({ [e.target.name]: e.target.checked });
    };

    render() {
        const language = localStorage.getItem("language");
        const { loading, profile } = this.state;

        return (
            <Container maxWidth="xl">
                <Loading open={loading} />
                <SettingSaleTeamManage
                    state={this.state}
                    profile={profile}
                    language={language}
                    onClickEdit={this.onClickEdit}
                    onChangeTeam={this.onChangeTeam}
                />
            </Container>
        );
    }
}

export default withRouter(SettingTeamSale);
