import { Container } from "@material-ui/core";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import Report from "./components/Report";
import { GET, GETACCOUNTTYPEALL, GETMT5ALL, GETMT5TRANSACTION, GETMT5TRANSACTIONWITHPAGE, POST } from "service";
import Swal from "sweetalert2";
import moment from "moment";
import Loading from "components/Loading/Loading";

class ReportMT5 extends Component {
     constructor(props) {
          super(props);

          this.state = {
               loading: false,
               date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
               date_end: new Date(),
               mt5_list: [
                    { label: "All", value: "all" },
               ],
               accountType_list: [
                    { label: "All", value: "all" },
               ],
               mt5: 'all',
               accountType: 'all',
               page: 1,
               data_table: [],
               language: localStorage.getItem("language"),
               data_lot: {},
               data_mt5: {},
               count_page: 0,
               mt5_select: "All",
               accountType_select: "All",
          };
     }

     componentDidMount = () => {
          this.getMT5Select();
          this.getAcoountSelect();
          this.getDataMT5();
          this.getDataMT5WithPage(1);
     };

     getMT5Select = async (id) => {
          let { mt5_list, accountType } = this.state;
          try {
               let getmt5 = await POST(GETMT5ALL, { account_type_id: id ? id : accountType });
               if (getmt5.success) {
                    let data = [{ label: "All", value: "all" }];
                    getmt5.result.map((el) => {
                         data.push({ label: el.mt4, value: el.mt4 });
                    });
                    // getmt5.result.map((el) => {
                    //      mt5_list.push({ label: el.mt4, value: el.mt4 });
                    // });
                    this.setState({ mt5_list: data, data_mt5: getmt5.result });
               } else {
                    Swal.fire({
                         title: "Warning", text: mt5_list.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               Swal.fire({
                    title: "Warning", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     getAcoountSelect = async () => {
          let { accountType_list, language } = this.state;
          try {
               let res = await POST(GETACCOUNTTYPEALL, { lang: language });
               if (res.success) {
                    let data = [{ label: "All", value: "all" }];
                    res.result.map((el) => {
                         data.push({ label: el.account_type_name, value: el.account_type_id });
                    });
                    // res.result.map((el) => {
                    //      accountType_list.push({ label: el.account_type_name, value: el.account_type_id });
                    // });
                    this.setState({ accountType_list: data });
               } else {
                    Swal.fire({
                         title: "Warning", text: res.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               Swal.fire({
                    title: "Warning", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     getDataMT5 = async () => {
          const { mt5, date_start, date_end, language, accountType } = this.state;
          let start = moment(date_start).format("YYYY-MM-DD");
          let end = moment(date_end).format("YYYY-MM-DD");
          try {
               // this.setState({ loading: true });
               if (mt5 !== "null") {
                    let MT5 = await POST(GETMT5TRANSACTION, { account_type_id: accountType, mt5, date_start: start, date_end: end });
                    if (MT5.success) {
                         this.setState({
                              data_lot: MT5.result.dataHeader
                         });
                         // this.setState({ loading: false });
                    } else {
                         Swal.fire({
                              title: "Warning", text: MT5.message, icon: "warning",
                              showConfirmButton: true
                         });
                    }
               } else {
                    Swal.fire({
                         title: "Warning", text: `${language === "th" ? "กรุณาเลือก MT5" : "Please Select MT5"}`, icon: "warning",
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               Swal.fire({
                    title: "Warning", text: error, icon: "warning",
                    showConfirmButton: true
               });
          }
     };

     getDataMT5WithPage = async (value) => {
          const { mt5, date_start, date_end, language, accountType } = this.state;
          let start = moment(date_start).format("YYYY-MM-DD");
          let end = moment(date_end).format("YYYY-MM-DD");

          try {
               this.setState({ loading: true });
               if (mt5 !== "null") {
                    let MT5 = await POST(GETMT5TRANSACTIONWITHPAGE, { account_type_id: accountType, mt5, date_start: start, date_end: end, page: value });
                    if (MT5.success) {
                         this.setState({
                              data_table: MT5.result.transaction, count_page: MT5.result.count_transaction
                         });
                         this.setState({ loading: false });
                    } else {
                         Swal.fire({
                              title: "Warning", text: MT5.message, icon: "warning",
                              showConfirmButton: true
                         });
                    }
               } else {
                    this.setState({ loading: false });
                    Swal.fire({
                         title: "Warning", text: `${language === "th" ? "กรุณาเลือก MT5" : "Please Select MT5"}`, icon: "warning",
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               Swal.fire({
                    title: "Warning", text: error, icon: "warning",
                    showConfirmButton: true
               });
          }
     };

     onClickSearch = async () => {
          // const { mt5, date_start, date_end, language, accountType } = this.state;
          // let start = moment(date_start).format("YYYY-MM-DD");
          // let end = moment(date_end).format("YYYY-MM-DD");
          // try {
          //      this.setState({ loading: true });
          //      if (mt5 !== "null") {
          //           let MT5 = await POST(GETMT5TRANSACTION, { account_type_id: accountType, mt5, date_start: start, date_end: end, page: 1 });
          //           if (MT5.success) {
          //                this.setState({
          //                     data_table: MT5.result.transaction, data_lot: MT5.result.dataHeader, page: 1
          //                });
          //                this.setState({ loading: false });
          //           } else {
          //                Swal.fire({
          //                     title: "Warning", text: MT5.message, icon: "warning",
          //                     // timer: 6000, 
          //                     showConfirmButton: true
          //                });
          //           }
          //      } else {
          //           Swal.fire({
          //                title: "Warning", text: `${language === "th" ? "กรุณาเลือก MT5" : "Please Select MT5"}`, icon: "warning",
          //                // timer: 6000, 
          //                showConfirmButton: true
          //           });
          //      }
          // } catch (error) {
          //      Swal.fire({
          //           title: "Warning", text: error, icon: "warning",
          //           // timer: 6000, 
          //           showConfirmButton: true
          //      });
          // }
          this.setState({ data_lot: {}, data_table: [] })
          this.getDataMT5();
          this.getDataMT5WithPage(1);
     };

     handleChangePage = (e, value) => {
          this.setState({ page: value });
          this.getDataMT5WithPage(value);
     };

     handleChange = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     handleDateStartChange = (date) => {
          this.setState({ date_start: date });
     };

     handleDateEndChange = (date) => {
          this.setState({ date_end: date });
     };

     onChangeAutocomplete = (name, valueAuto) => {
          // console.log('valueAuto', valueAuto);
          if (name === "search_mt5") {
               this.setState({ mt5: valueAuto.value, mt5_select: valueAuto.label });
          } else {
               this.getMT5Select(valueAuto.value);
               this.setState({ mt5: 'all', mt5_select: 'All' });
               this.setState({ accountType: valueAuto.value, accountType_select: valueAuto.label });
          }
     };


     render() {
          const { loading, mt5_list, page, data_table, language, sum_lot, data_lot, data_mt5, accountType_list, count_page } = this.state;
          return (
               <Container maxWidth="xl" sx={{ position: "relative" }}>
                    <Loading open={loading} />
                    <Report
                         language={language}
                         state={this.state}
                         mt5_list={mt5_list}
                         handleChange={this.onChangeAutocomplete}
                         handleDateStartChange={this.handleDateStartChange}
                         handleDateEndChange={this.handleDateEndChange}
                         page={page}
                         data={data_table}
                         sum_lot={sum_lot}
                         onClickSearch={this.onClickSearch}
                         handleChangePage={this.handleChangePage}
                         data_lot={data_lot}
                         data_mt5={data_mt5}
                         accountType_list={accountType_list}
                         count_page={count_page}
                    />
               </Container>
          );
     }
}

export default withRouter(ReportMT5);
